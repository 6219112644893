/* eslint-disable */

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { FieldPolicy, FieldReadFunction, TypePolicies, TypePolicy } from '@apollo/client/cache';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /**
   * Implement the DateTime<Utc> scalar
   *
   * The input/output is a string in RFC3339 format.
   */
  DateTime: { input: string; output: string; }
  /** A scalar that can represent any JSON value. */
  JSON: { input: Record<string, unknown>; output: Record<string, unknown>; }
  MemoryAddress: { input: bigint; output: bigint; }
  /**
   * ISO 8601 combined date and time without timezone.
   *
   * # Examples
   *
   * * `2015-07-01T08:59:60.123`,
   */
  NaiveDateTime: { input: string; output: string; }
  /**
   * A UUID is a unique 128-bit number, stored as 16 octets. UUIDs are parsed as
   * Strings within GraphQL. UUIDs are used to assign unique identifiers to
   * entities without requiring a central allocating authority.
   *
   * # References
   *
   * * [Wikipedia: Universally Unique Identifier](http://en.wikipedia.org/wiki/Universally_unique_identifier)
   * * [RFC4122: A Universally Unique IDentifier (UUID) URN Namespace](http://tools.ietf.org/html/rfc4122)
   */
  UUID: { input: string; output: string; }
  Upload: { input: File; output: File; }
};

export type AppConfig = {
  __typename?: 'AppConfig';
  apiUrl: Scalars['String']['output'];
  frontendUrl: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type Artifact = {
  __typename?: 'Artifact';
  name: Scalars['String']['output'];
  signature?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

export type Attachment = {
  __typename?: 'Attachment';
  data: Array<Scalars['Int']['output']>;
  id: Scalars['UUID']['output'];
  mimeType: Scalars['String']['output'];
  name: Scalars['String']['output'];
  run: Run;
  runId: Scalars['UUID']['output'];
};

export type Binary = {
  __typename?: 'Binary';
  active: Scalars['Boolean']['output'];
  analysis?: Maybe<BinaryAnalysis>;
  chip: Chip;
  chipId: Scalars['UUID']['output'];
  creationDate: Scalars['DateTime']['output'];
  deletionDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['UUID']['output'];
  parts: Array<BinaryPart>;
  version: Scalars['String']['output'];
  versionMajor: Scalars['Int']['output'];
  versionMinor: Scalars['Int']['output'];
  versionPatch: Scalars['Int']['output'];
};

export type BinaryAnalysis = {
  __typename?: 'BinaryAnalysis';
  nvmSize: Scalars['Int']['output'];
};

export enum BinaryKind {
  Bin = 'BIN',
  Elf = 'ELF',
  Hex = 'HEX'
}

export type BinaryPart = {
  __typename?: 'BinaryPart';
  analysis?: Maybe<BinaryPartAnalysis>;
  binaryId: Scalars['UUID']['output'];
  changeDate: Scalars['NaiveDateTime']['output'];
  creationDate: Scalars['NaiveDateTime']['output'];
  id: Scalars['UUID']['output'];
  imageHash: Array<Scalars['Int']['output']>;
  kind: BinaryKind | '%future added value';
  memoryOffset?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type BinaryPartAnalysis = {
  __typename?: 'BinaryPartAnalysis';
  nvmSize: Scalars['Int']['output'];
  rtt: RttAnalysis;
};

export type Block = {
  __typename?: 'Block';
  /**
   * The data this block stores, in a continuous byte slice.
   *
   * This automatically transfers the stored data into a slice of bytes which can be written
   * to device memory.
   */
  byteSlice: Array<Scalars['Int']['output']>;
  /** The data this block stores, in JSON format. */
  dataDecoded: Scalars['JSON']['output'];
  /** The globally unique ID of this block. */
  id: Scalars['UUID']['output'];
  /** The device this block belongs to. */
  run: Run;
  /**
   * The schema of the block.
   *
   * The schema is a contract that has to be fulfilled for the block to be created.
   */
  schema: BlockSchema;
};


export type BlockByteSliceArgs = {
  chipId: Scalars['UUID']['input'];
};

export type BlockSchema = {
  __typename?: 'BlockSchema';
  active: Scalars['Boolean']['output'];
  creationDate: Scalars['DateTime']['output'];
  deletionDate?: Maybe<Scalars['DateTime']['output']>;
  displayName: Scalars['String']['output'];
  generator?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  isDeviceIdentifier: Scalars['Boolean']['output'];
  memorySink: MemoryEndpoint;
  memorySinks: Array<MemoryEndpoint>;
  memorySource?: Maybe<MemoryEndpoint>;
  name: Scalars['String']['output'];
  projectId: Scalars['UUID']['output'];
  requirements: Array<Requirement>;
  schema: Scalars['String']['output'];
  /** Determine if this schema can be used for requirements. */
  supportsRequirements: Scalars['Boolean']['output'];
};


export type BlockSchemaMemorySinkArgs = {
  chipId: Scalars['UUID']['input'];
};

export type Chip = {
  __typename?: 'Chip';
  active: Scalars['Boolean']['output'];
  binaries: Array<Binary>;
  binary: Binary;
  /** Returns the newest binary for given project id according to semver. */
  binaryNewest?: Maybe<Binary>;
  changeDate: Scalars['DateTime']['output'];
  creationDate: Scalars['DateTime']['output'];
  deletionDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['UUID']['output'];
  /** Returns true if the chip part number is natively supported by probe-rs. */
  isSupported: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  nvmSize?: Maybe<Scalars['Int']['output']>;
  partNumber: Scalars['String']['output'];
  projectId: Scalars['UUID']['output'];
};


export type ChipBinaryArgs = {
  id: Scalars['UUID']['input'];
};

export type ChipEndpoint = {
  address: Scalars['Int']['input'];
  chipId: Scalars['UUID']['input'];
  length: Scalars['Int']['input'];
  transform: Scalars['String']['input'];
};

export type DataBlock = {
  __typename?: 'DataBlock';
  blockSchema: BlockSchema;
};

export type Device = {
  __typename?: 'Device';
  /** Whether or not the device is active. */
  active: Scalars['Boolean']['output'];
  changeDate: Scalars['DateTime']['output'];
  creationDate: Scalars['DateTime']['output'];
  /** The date when the device was deactivated. */
  deletionDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['UUID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  project: Project;
  projectId: Scalars['UUID']['output'];
  /**
   * The run with given UUID.
   *
   * If no UUID is given, the most recent run is returned.
   */
  run: Run;
  runs: Array<Run>;
};


export type DeviceRunArgs = {
  id?: InputMaybe<Scalars['UUID']['input']>;
};

export type DeviceConnection = {
  __typename?: 'DeviceConnection';
  /** A list of edges. */
  edges: Array<DeviceEdge>;
  /** A list of nodes. */
  nodes: Array<Device>;
  numDevices: Scalars['Int']['output'];
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type DeviceEdge = {
  __typename?: 'DeviceEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Device;
};

export type Family = {
  __typename?: 'Family';
  definition: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  owner: Owner;
};

export type FinishStepData = {
  binaryId?: InputMaybe<Scalars['UUID']['input']>;
};

export type FlashChip = {
  __typename?: 'FlashChip';
  chip: Chip;
  chipId: Scalars['UUID']['output'];
  command?: Maybe<Scalars['String']['output']>;
};

export type Group = {
  __typename?: 'Group';
  changeDate: Scalars['DateTime']['output'];
  creationDate: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  projects: Array<Project>;
  users: Array<User>;
};

export type InputDataBlock = {
  blockSchemaId?: InputMaybe<Scalars['UUID']['input']>;
};

export type InputFlashChip = {
  chipId?: InputMaybe<Scalars['UUID']['input']>;
  command?: InputMaybe<Scalars['String']['input']>;
};

export type InputIntegrationsSettings = {
  matrix: InputMatrixSettings;
  telegram: InputTelegramSettings;
};

export type InputMatrixSettings = {
  rooms: Array<Scalars['String']['input']>;
};

export type InputPlaybook = {
  stations: Array<InputStation>;
  version?: InputMaybe<Scalars['Int']['input']>;
};

export type InputProjectSettings = {
  integrations: InputIntegrationsSettings;
};

export type InputRunCommand = {
  command?: InputMaybe<Scalars['String']['input']>;
  directory?: InputMaybe<Scalars['String']['input']>;
};

export type InputStation = {
  color?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  steps: Array<InputStep>;
};

export type InputStep = {
  id: Scalars['UUID']['input'];
  kind: InputStepKind;
  label?: InputMaybe<Scalars['String']['input']>;
};

export type InputStepKind = {
  dataBlock?: InputMaybe<InputDataBlock>;
  flashChip?: InputMaybe<InputFlashChip>;
  runCommand?: InputMaybe<InputRunCommand>;
};

export type InputTelegramSettings = {
  chatIds: Array<Scalars['Int']['input']>;
};

export type InsertBinaryPart = {
  image: Scalars['Upload']['input'];
  kind: BinaryKind | '%future added value';
  memoryOffset?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type IntegrationsSettings = {
  __typename?: 'IntegrationsSettings';
  matrix: MatrixSettings;
  telegram: TelegramSettings;
};

export type Log = {
  __typename?: 'Log';
  creationDate: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  level: Scalars['String']['output'];
  message: Scalars['String']['output'];
  run: Run;
  runId: Scalars['UUID']['output'];
};

export type MatrixSettings = {
  __typename?: 'MatrixSettings';
  rooms: Array<Scalars['String']['output']>;
};

export type MemoryEndpoint = {
  __typename?: 'MemoryEndpoint';
  active: Scalars['Boolean']['output'];
  address: Scalars['MemoryAddress']['output'];
  chip: Chip;
  creationDate: Scalars['DateTime']['output'];
  deletionDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['UUID']['output'];
  length: Scalars['Int']['output'];
  schema: BlockSchema;
  sink: Scalars['Boolean']['output'];
};

export type MutationRoot = {
  __typename?: 'MutationRoot';
  /** Creates a new attachment record and returns the ID of the created record. */
  attachmentCreate: Attachment;
  /** Creates a new binary with the specified chip */
  binaryCreate: Binary;
  /** Delete a binary, making it no longer appear as being linked to the project. */
  binaryDelete: Scalars['UUID']['output'];
  /** Creates a new block for given `project_id` and returns the block. */
  blockCreate: Block;
  blockCreateFromBytes: Block;
  /** Creates a new block_schema and returns the block_schema. */
  blockSchemaCreate: BlockSchema;
  /** Delete a block schema, making it no longer appear as being linked to the project. */
  blockSchemaDelete: Scalars['UUID']['output'];
  /** Re-name a block schema */
  blockSchemaRename: Scalars['UUID']['output'];
  blockSchemaSetIdentifier: Scalars['UUID']['output'];
  /** Change the current user's password. */
  changePassword: Scalars['Boolean']['output'];
  /** Creates a new project for a user and returns the project upon successful creation. */
  chipCreate: Chip;
  /** Delete a chip, making it no longer appear as being linked to the project. */
  chipDelete: Scalars['UUID']['output'];
  /** Creates a new device for given `project_id` and returns the device. */
  deviceCreate: Device;
  /** Delete a device, making it no longer appear as being linked to the project. */
  deviceDelete: Scalars['UUID']['output'];
  /** Creates a new family for a user and returns the family upon successful creation. */
  familyCreate: Family;
  /** Start a provisioning step on the currently-active run. */
  finishStep: Scalars['UUID']['output'];
  groupAddUser: Scalars['UUID']['output'];
  groupCreate: Group;
  groupRemoveUser: Scalars['UUID']['output'];
  /** Creates a new log for given `project_id` and returns the log. */
  logCreate: Log;
  /** Updates the subscription for the current user. */
  paymentMethodCreate: PaymentMethod;
  playbookFromYaml: Scalars['Int']['output'];
  playbookSave: Scalars['Int']['output'];
  /** Updates the draft playbook with the given new playbook. */
  playbookUpdate: Scalars['Boolean']['output'];
  /** Creates a new project for a user and returns the project upon successful creation. */
  projectCreate: Project;
  /** Delete a project, making it no longer appear as being linked to the user. */
  projectDelete: Scalars['UUID']['output'];
  /** Update settings on a project. */
  projectSettingsUpdate: Scalars['UUID']['output'];
  /** Change the owner of a project to a new user or group */
  projectTransfer: Scalars['UUID']['output'];
  provisionerAssignStation: Provisioner;
  provisionerCreate: Provisioner;
  /** Delete a provisioner, making it no longer appear as being linked to the project. */
  provisionerDelete: Scalars['UUID']['output'];
  provisionerRegenerateToken: Provisioner;
  provisionerRename: Provisioner;
  refresh: Scalars['String']['output'];
  /** Create a new requirement for given `project_id`. */
  requirementCreate: Requirement;
  /** Delete a requirement,making it no longer linked to the project. */
  requirementDelete: Scalars['UUID']['output'];
  /** Creates a new run for the currently in-session device and returns the run. */
  runCreate: Run;
  /** Creates a new run for an existing device and returns the run. */
  runCreateFor: Run;
  /** Finish provisioning for a run. */
  runFinish: Scalars['UUID']['output'];
  signIn: Scalars['String']['output'];
  /**
   * Sign in with a given OAuth provider.
   *
   * # Arguments
   * * `provider`: The provider to be used e.g. `google` or `github`.
   * * `code`: The code that was returned from the request we sent to the provider.
   * For more info see documentation on OAuth CSRF attack mitigation.
   * * `nonce`: The nonce that was sent to the OIDC provider to mitigate replay attacks.
   * This nonce is only required for real OIDC providers and not all OAuth providers.
   */
  signInWithOauth: Scalars['String']['output'];
  /** Start a provisioning step on the currently-active run. */
  startStep: Scalars['UUID']['output'];
  /** Updates the subscription for the current user. */
  subscriptionUpdate?: Maybe<Scalars['String']['output']>;
  userApprove: Scalars['UUID']['output'];
  userConfirm: Scalars['Boolean']['output'];
  userCreate: User;
  userRequestPasswordReset: Scalars['Boolean']['output'];
  userResendConfirmCode: Scalars['Boolean']['output'];
  userResetPassword: Scalars['Boolean']['output'];
};


export type MutationRootAttachmentCreateArgs = {
  data: Scalars['Upload']['input'];
};


export type MutationRootBinaryCreateArgs = {
  chipId: Scalars['UUID']['input'];
  parts: Array<InsertBinaryPart>;
  version: Scalars['String']['input'];
};


export type MutationRootBinaryDeleteArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationRootBlockCreateArgs = {
  data: Scalars['JSON']['input'];
  schemaName: Scalars['String']['input'];
};


export type MutationRootBlockCreateFromBytesArgs = {
  data: Array<Scalars['Int']['input']>;
  schemaName: Scalars['String']['input'];
};


export type MutationRootBlockSchemaCreateArgs = {
  generator?: InputMaybe<Scalars['String']['input']>;
  isDeviceIdentifier: Scalars['Boolean']['input'];
  memorySinks: Array<ChipEndpoint>;
  memorySource?: InputMaybe<ChipEndpoint>;
  name: Scalars['String']['input'];
  projectId: Scalars['UUID']['input'];
  schema: Scalars['String']['input'];
};


export type MutationRootBlockSchemaDeleteArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationRootBlockSchemaRenameArgs = {
  id: Scalars['UUID']['input'];
  name: Scalars['String']['input'];
};


export type MutationRootBlockSchemaSetIdentifierArgs = {
  id: Scalars['UUID']['input'];
  identifier: Scalars['Boolean']['input'];
};


export type MutationRootChangePasswordArgs = {
  currentPassword: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
};


export type MutationRootChipCreateArgs = {
  name: Scalars['String']['input'];
  partNumber: Scalars['String']['input'];
  projectId: Scalars['UUID']['input'];
};


export type MutationRootChipDeleteArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationRootDeviceDeleteArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationRootFamilyCreateArgs = {
  definition: Scalars['String']['input'];
};


export type MutationRootFinishStepArgs = {
  data?: InputMaybe<FinishStepData>;
  passed: Scalars['Boolean']['input'];
};


export type MutationRootGroupAddUserArgs = {
  groupId: Scalars['UUID']['input'];
  userEmail: Scalars['String']['input'];
};


export type MutationRootGroupCreateArgs = {
  name: Scalars['String']['input'];
};


export type MutationRootGroupRemoveUserArgs = {
  groupId: Scalars['UUID']['input'];
  userId: Scalars['UUID']['input'];
};


export type MutationRootLogCreateArgs = {
  level: Scalars['String']['input'];
  message: Scalars['String']['input'];
};


export type MutationRootPaymentMethodCreateArgs = {
  cvc: Scalars['String']['input'];
  expMonth: Scalars['Int']['input'];
  expYear: Scalars['Int']['input'];
  number: Scalars['String']['input'];
};


export type MutationRootPlaybookFromYamlArgs = {
  projectId: Scalars['UUID']['input'];
  yaml: Scalars['String']['input'];
};


export type MutationRootPlaybookSaveArgs = {
  projectId: Scalars['UUID']['input'];
};


export type MutationRootPlaybookUpdateArgs = {
  playbook: InputPlaybook;
  projectId: Scalars['UUID']['input'];
};


export type MutationRootProjectCreateArgs = {
  groupOwner?: InputMaybe<Scalars['UUID']['input']>;
  name: Scalars['String']['input'];
};


export type MutationRootProjectDeleteArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationRootProjectSettingsUpdateArgs = {
  id: Scalars['UUID']['input'];
  settings: InputProjectSettings;
};


export type MutationRootProjectTransferArgs = {
  id: Scalars['UUID']['input'];
  ownerId: Scalars['UUID']['input'];
};


export type MutationRootProvisionerAssignStationArgs = {
  id: Scalars['UUID']['input'];
  station: Scalars['String']['input'];
};


export type MutationRootProvisionerCreateArgs = {
  name: Scalars['String']['input'];
  projectId: Scalars['UUID']['input'];
  station: Scalars['String']['input'];
};


export type MutationRootProvisionerDeleteArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationRootProvisionerRegenerateTokenArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationRootProvisionerRenameArgs = {
  id: Scalars['UUID']['input'];
  name: Scalars['String']['input'];
};


export type MutationRootRefreshArgs = {
  impersonate?: InputMaybe<Scalars['UUID']['input']>;
  token: Scalars['String']['input'];
};


export type MutationRootRequirementCreateArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  inclusiveLower: Scalars['Boolean']['input'];
  inclusiveUpper: Scalars['Boolean']['input'];
  lowerLimit?: InputMaybe<Scalars['Float']['input']>;
  name: Scalars['String']['input'];
  projectId: Scalars['UUID']['input'];
  schemaId: Scalars['UUID']['input'];
  upperLimit?: InputMaybe<Scalars['Float']['input']>;
};


export type MutationRootRequirementDeleteArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationRootRunCreateArgs = {
  station: Scalars['String']['input'];
};


export type MutationRootRunCreateForArgs = {
  identifier: Scalars['String']['input'];
  station: Scalars['String']['input'];
};


export type MutationRootSignInArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationRootSignInWithOauthArgs = {
  code: Scalars['String']['input'];
  csrfToken?: InputMaybe<Scalars['String']['input']>;
  nonce?: InputMaybe<Scalars['String']['input']>;
  provider: Scalars['String']['input'];
};


export type MutationRootStartStepArgs = {
  description: Scalars['String']['input'];
};


export type MutationRootSubscriptionUpdateArgs = {
  paymentMethod?: InputMaybe<Scalars['String']['input']>;
  tier: Tier;
};


export type MutationRootUserApproveArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationRootUserConfirmArgs = {
  token: Scalars['String']['input'];
};


export type MutationRootUserCreateArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationRootUserRequestPasswordResetArgs = {
  email: Scalars['String']['input'];
};


export type MutationRootUserResendConfirmCodeArgs = {
  email: Scalars['String']['input'];
};


export type MutationRootUserResetPasswordArgs = {
  newPassword: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

export type Notification = {
  __typename?: 'Notification';
  creationDate: Scalars['DateTime']['output'];
  deliveryDate?: Maybe<Scalars['DateTime']['output']>;
  event: Scalars['JSON']['output'];
  id: Scalars['UUID']['output'];
  project?: Maybe<Project>;
  projectId?: Maybe<Scalars['UUID']['output']>;
  retry: Scalars['Int']['output'];
};

export type NotificationConnection = {
  __typename?: 'NotificationConnection';
  /** A list of edges. */
  edges: Array<NotificationEdge>;
  /** A list of nodes. */
  nodes: Array<Notification>;
  numNotifications: Scalars['Int']['output'];
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type NotificationEdge = {
  __typename?: 'NotificationEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: Notification;
};

export type OauthData = {
  __typename?: 'OauthData';
  authUrl: Scalars['String']['output'];
  csrfToken: Scalars['String']['output'];
  nonce?: Maybe<Scalars['String']['output']>;
};

export type OauthUser = {
  __typename?: 'OauthUser';
  provider: Scalars['String']['output'];
  subject: Scalars['String']['output'];
};

export type Owner = Group | User | { __typename?: "%other" };

/** Information about pagination in a connection */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  id: Scalars['String']['output'];
};

export type Playbook = {
  __typename?: 'Playbook';
  project: Project;
  projectId: Scalars['UUID']['output'];
  station?: Maybe<Station>;
  stations: Array<Station>;
  version?: Maybe<Scalars['Int']['output']>;
};


export type PlaybookStationArgs = {
  name: Scalars['String']['input'];
};

export type Project = {
  __typename?: 'Project';
  active: Scalars['Boolean']['output'];
  blockSchema: BlockSchema;
  blockSchemas: Array<BlockSchema>;
  changeDate: Scalars['DateTime']['output'];
  chip: Chip;
  chips: Array<Chip>;
  creationDate: Scalars['DateTime']['output'];
  deletionDate?: Maybe<Scalars['DateTime']['output']>;
  device: Device;
  devices: DeviceConnection;
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  notifications: NotificationConnection;
  owner: Owner;
  /** Get the currently-active playbook for the project. */
  playbook: Playbook;
  /** Get all playbooks registered with the project. */
  playbooks: Array<Playbook>;
  provisioner: Provisioner;
  provisioners: Array<Provisioner>;
  requirement: Requirement;
  requirements: Array<Requirement>;
  settings: ProjectSettings;
};


export type ProjectBlockSchemaArgs = {
  id: Scalars['UUID']['input'];
};


export type ProjectChipArgs = {
  id: Scalars['UUID']['input'];
};


export type ProjectDeviceArgs = {
  id: Scalars['UUID']['input'];
};


export type ProjectDevicesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type ProjectNotificationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};


export type ProjectProvisionerArgs = {
  id: Scalars['UUID']['input'];
};


export type ProjectRequirementArgs = {
  id: Scalars['UUID']['input'];
};

export type ProjectSettings = {
  __typename?: 'ProjectSettings';
  integrations: IntegrationsSettings;
};

export type Provisioner = {
  __typename?: 'Provisioner';
  active: Scalars['Boolean']['output'];
  changeDate: Scalars['DateTime']['output'];
  creationDate: Scalars['DateTime']['output'];
  currentDevice?: Maybe<Device>;
  currentDeviceId?: Maybe<Scalars['UUID']['output']>;
  currentRun?: Maybe<Run>;
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  project: Project;
  projectId: Scalars['UUID']['output'];
  station: Scalars['String']['output'];
  steps: Array<Step>;
  token?: Maybe<Scalars['String']['output']>;
};

export type ProvisionerReleases = {
  __typename?: 'ProvisionerReleases';
  ui: Array<Release>;
};

export type QueryRoot = {
  __typename?: 'QueryRoot';
  /** Returns all available targets. */
  availableTargets: Array<Target>;
  /** Returns the data that is required for the frontend to work. */
  config: AppConfig;
  currentProvisioner: Provisioner;
  currentUser: User;
  /** Returns a family with a certain `id`. */
  family: Family;
  group: Group;
  oauthUrl: OauthData;
  /** Returns a subscription with a certain `id`. */
  paymentMethod: PaymentMethod;
  paymentMethods: Array<PaymentMethod>;
  ping: Scalars['Boolean']['output'];
  /** Returns a project with a certain `id`. */
  project: Project;
  /** Lists all projects. */
  projects: Array<Project>;
  /** Returns the data that is required for the frontend to work. */
  provisionerReleases: ProvisionerReleases;
  /** Returns the data that is required for stripe to work in the frontend. */
  stripeData: StripeData;
  /** Returns a subscription with a certain `id`. */
  subscription: Subscription;
  users: Array<User>;
  usersPendingApproval: Array<User>;
};


export type QueryRootAvailableTargetsArgs = {
  targetFiles?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type QueryRootFamilyArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryRootGroupArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryRootOauthUrlArgs = {
  provider: Scalars['String']['input'];
};


export type QueryRootPaymentMethodArgs = {
  id: Scalars['String']['input'];
};


export type QueryRootProjectArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryRootSubscriptionArgs = {
  id: Scalars['String']['input'];
};

export type Release = {
  __typename?: 'Release';
  artifacts: Array<Artifact>;
  name?: Maybe<Scalars['String']['output']>;
  releaseNotes: Scalars['String']['output'];
  version: Scalars['String']['output'];
};

export type Requirement = {
  __typename?: 'Requirement';
  /** Whether or not the requirement is active. */
  active: Scalars['Boolean']['output'];
  /** The date that the requirement was created. */
  creationDate: Scalars['DateTime']['output'];
  /** The date when the requirement was deactivated. */
  deletionDate?: Maybe<Scalars['DateTime']['output']>;
  /** The description of the requirement. */
  description?: Maybe<Scalars['String']['output']>;
  /** The ID of the requirement. */
  id: Scalars['UUID']['output'];
  /** Indicates if the lower bound is inclusive or exclusive. */
  inclusiveLower: Scalars['Boolean']['output'];
  /** Indicates if the upper bound is inclusive or exclusive. */
  inclusiveUpper: Scalars['Boolean']['output'];
  /** The lower bounds limit of the requirement. */
  lowerLimit?: Maybe<Scalars['Float']['output']>;
  /** The name of the requirement. */
  name: Scalars['String']['output'];
  /** The [`BlockSchema`] associated with the requirement's input data. */
  schema: BlockSchema;
  /** The ID of the [`BlockSchema`] used as the source of the requirement data. */
  schemaId: Scalars['UUID']['output'];
  /** The upper bounds limit of the requirement. */
  upperLimit?: Maybe<Scalars['Float']['output']>;
};

export type RequirementCheck = {
  __typename?: 'RequirementCheck';
  block?: Maybe<Block>;
  blockId?: Maybe<Scalars['UUID']['output']>;
  checkDate: Scalars['DateTime']['output'];
  id: Scalars['UUID']['output'];
  passed: Scalars['Boolean']['output'];
  requirement: Requirement;
  requirementId: Scalars['UUID']['output'];
  runId: Scalars['UUID']['output'];
};

export type RttAnalysis = {
  __typename?: 'RttAnalysis';
  headerLocation?: Maybe<Scalars['Int']['output']>;
};

export type Run = {
  __typename?: 'Run';
  attachments: Array<Attachment>;
  block: Block;
  blockForSchema?: Maybe<Block>;
  blockSchemas: Array<BlockSchema>;
  blocks: Array<Block>;
  changeDate: Scalars['DateTime']['output'];
  creationDate: Scalars['DateTime']['output'];
  device: Device;
  deviceId: Scalars['UUID']['output'];
  /** Reports any reasons for a run failure. */
  errors: Array<Scalars['String']['output']>;
  finished: Scalars['Boolean']['output'];
  id: Scalars['UUID']['output'];
  logs: Array<Log>;
  projectId: Scalars['UUID']['output'];
  provisioner?: Maybe<Provisioner>;
  requirementChecks: Array<RequirementCheck>;
  requirements: Array<Requirement>;
  /** Get the overall status of the run. */
  runStatus: StepStatus | '%future added value';
  station: Scalars['String']['output'];
  steps: Array<RunStep>;
};


export type RunBlockArgs = {
  id: Scalars['UUID']['input'];
};


export type RunBlockForSchemaArgs = {
  schemaId: Scalars['UUID']['input'];
};

export type RunCommand = {
  __typename?: 'RunCommand';
  command?: Maybe<Scalars['String']['output']>;
  directory?: Maybe<Scalars['String']['output']>;
};

export type RunStep = {
  __typename?: 'RunStep';
  changeDate: Scalars['DateTime']['output'];
  creationDate: Scalars['DateTime']['output'];
  data: RunStepData;
  id: Scalars['UUID']['output'];
  runId: Scalars['UUID']['output'];
  station: Scalars['String']['output'];
  status: StepStatus | '%future added value';
  step: Step;
  stepId: Scalars['UUID']['output'];
};

export type RunStepData = {
  __typename?: 'RunStepData';
  binary?: Maybe<Binary>;
  binaryId?: Maybe<Scalars['UUID']['output']>;
};

export type Station = {
  __typename?: 'Station';
  color: Scalars['String']['output'];
  name: Scalars['String']['output'];
  steps: Array<Step>;
};

export type Step = {
  __typename?: 'Step';
  changeDate: Scalars['NaiveDateTime']['output'];
  creationDate: Scalars['NaiveDateTime']['output'];
  id: Scalars['UUID']['output'];
  kind: StepKind;
  label?: Maybe<Scalars['String']['output']>;
  position: Scalars['Int']['output'];
  station: Scalars['String']['output'];
};

export type StepKind = DataBlock | FlashChip | RunCommand | { __typename?: "%other" };

export enum StepStatus {
  Fail = 'FAIL',
  Pass = 'PASS',
  Pending = 'PENDING'
}

export type StripeData = {
  __typename?: 'StripeData';
  publicKey: Scalars['String']['output'];
};

export type Subscription = {
  __typename?: 'Subscription';
  id: Scalars['String']['output'];
  user: User;
};

export type SubscriptionRoot = {
  __typename?: 'SubscriptionRoot';
  /** Yields a new device whenever a new device is created. */
  createBlockEvent: Block;
  /** Yields a new block_schema whenever a new block_schema is created. */
  createBlockSchemaEvent: BlockSchema;
  /** Yields a new device whenever a new device is created. */
  createDeviceEvent: Device;
  /** Yields a new requirement whenever one is created. */
  createRequirementEvent: Requirement;
  /** Yields a new device whenever a new device is created. */
  createRunEvent: Run;
  /** Yields a new device whenever a new device is created. */
  logEvent: Log;
};


export type SubscriptionRootCreateBlockEventArgs = {
  runId: Scalars['UUID']['input'];
  token: Scalars['String']['input'];
};


export type SubscriptionRootCreateBlockSchemaEventArgs = {
  projectId: Scalars['UUID']['input'];
  token: Scalars['String']['input'];
};


export type SubscriptionRootCreateDeviceEventArgs = {
  projectId: Scalars['UUID']['input'];
  token: Scalars['String']['input'];
};


export type SubscriptionRootCreateRequirementEventArgs = {
  projectId: Scalars['UUID']['input'];
  token: Scalars['String']['input'];
};


export type SubscriptionRootCreateRunEventArgs = {
  deviceId: Scalars['UUID']['input'];
  token: Scalars['String']['input'];
};


export type SubscriptionRootLogEventArgs = {
  runId: Scalars['UUID']['input'];
  token: Scalars['String']['input'];
};

export type Target = {
  __typename?: 'Target';
  name: Scalars['String']['output'];
};

export type TelegramSettings = {
  __typename?: 'TelegramSettings';
  chatIds: Array<Scalars['Int']['output']>;
};

export enum Tier {
  /** The basic service with all it's basic features. */
  Base = 'BASE',
  /** Only a very limited free featureset. */
  Free = 'FREE',
  /** The full featureset unlocked. */
  Full = 'FULL'
}

export type User = {
  __typename?: 'User';
  admin: Scalars['Boolean']['output'];
  changeDate: Scalars['DateTime']['output'];
  creationDate: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  families: Array<Family>;
  groups: Array<Group>;
  id: Scalars['UUID']['output'];
  oauthUser?: Maybe<OauthUser>;
  projects: Array<Project>;
  stripeCustomerId?: Maybe<Scalars['String']['output']>;
  subscription?: Maybe<Subscription>;
  tier: Tier | '%future added value';
  userType: UserType | '%future added value';
};

export enum UserType {
  Native = 'NATIVE',
  Oauth = 'OAUTH'
}

export type Current_UserQueryVariables = Exact<{ [key: string]: never; }>;


export type Current_UserQuery = { __typename?: 'QueryRoot', currentUser: { __typename?: 'User', email: string } };

export type SignInMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type SignInMutation = { __typename?: 'MutationRoot', signIn: string };

export type SignInWithOAuthMutationVariables = Exact<{
  provider: Scalars['String']['input'];
  code: Scalars['String']['input'];
  nonce?: InputMaybe<Scalars['String']['input']>;
}>;


export type SignInWithOAuthMutation = { __typename?: 'MutationRoot', signInWithOauth: string };

export type OAuthUrlQueryVariables = Exact<{
  provider: Scalars['String']['input'];
}>;


export type OAuthUrlQuery = { __typename?: 'QueryRoot', oauthUrl: { __typename?: 'OauthData', authUrl: string, nonce?: string | null } };

export type ReleasesQueryVariables = Exact<{ [key: string]: never; }>;


export type ReleasesQuery = { __typename?: 'QueryRoot', provisionerReleases: { __typename?: 'ProvisionerReleases', ui: Array<{ __typename?: 'Release', name?: string | null, version: string, releaseNotes: string, artifacts: Array<{ __typename?: 'Artifact', name: string, url: string }> }> } };

export type UserQueryVariables = Exact<{ [key: string]: never; }>;


export type UserQuery = { __typename?: 'QueryRoot', currentUser: { __typename?: 'User', id: string, email: string, creationDate: string, userType: UserType, groups: Array<{ __typename?: 'Group', id: string, name: string }> } };

export type ChangePasswordMutationVariables = Exact<{
  currentPassword: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
}>;


export type ChangePasswordMutation = { __typename?: 'MutationRoot', changePassword: boolean };

export type ConfirmUserMutationVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type ConfirmUserMutation = { __typename?: 'MutationRoot', userConfirm: boolean };

export type ResendConfirmationEmailMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type ResendConfirmationEmailMutation = { __typename?: 'MutationRoot', userResendConfirmCode: boolean };

export type CreateUserMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type CreateUserMutation = { __typename?: 'MutationRoot', userCreate: { __typename?: 'User', id: string } };

export type StripeDataQueryVariables = Exact<{ [key: string]: never; }>;


export type StripeDataQuery = { __typename?: 'QueryRoot', stripeData: { __typename?: 'StripeData', publicKey: string } };

export type UsersPendingApprovalQueryVariables = Exact<{ [key: string]: never; }>;


export type UsersPendingApprovalQuery = { __typename?: 'QueryRoot', usersPendingApproval: Array<{ __typename?: 'User', id: string, email: string, creationDate: string }> };

export type AllUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type AllUsersQuery = { __typename?: 'QueryRoot', users: Array<{ __typename?: 'User', id: string, email: string }> };

export type UserApproveMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;


export type UserApproveMutation = { __typename?: 'MutationRoot', userApprove: string };

export type RefreshWithImpersonationMutationVariables = Exact<{
  token: Scalars['String']['input'];
  impersonate?: InputMaybe<Scalars['UUID']['input']>;
}>;


export type RefreshWithImpersonationMutation = { __typename?: 'MutationRoot', refresh: string };

export type FamiliesQueryVariables = Exact<{ [key: string]: never; }>;


export type FamiliesQuery = { __typename?: 'QueryRoot', currentUser: { __typename?: 'User', id: string, families: Array<{ __typename?: 'Family', id: string, definition: string }> } };

export type FamilyQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;


export type FamilyQuery = { __typename?: 'QueryRoot', family: { __typename?: 'Family', id: string, definition: string } };

export type CreateFamilyMutationVariables = Exact<{
  definition: Scalars['String']['input'];
}>;


export type CreateFamilyMutation = { __typename?: 'MutationRoot', familyCreate: { __typename?: 'Family', id: string } };

export type GroupQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;


export type GroupQuery = { __typename?: 'QueryRoot', group: { __typename?: 'Group', name: string, creationDate: string, projects: Array<{ __typename?: 'Project', id: string, name: string }>, users: Array<{ __typename?: 'User', id: string, email: string }> } };

export type GroupAddUserMutationVariables = Exact<{
  group_id: Scalars['UUID']['input'];
  user_email: Scalars['String']['input'];
}>;


export type GroupAddUserMutation = { __typename?: 'MutationRoot', groupAddUser: string };

export type GroupRemoveUserMutationVariables = Exact<{
  group_id: Scalars['UUID']['input'];
  user_id: Scalars['UUID']['input'];
}>;


export type GroupRemoveUserMutation = { __typename?: 'MutationRoot', groupRemoveUser: string };

export type CreateGroupMutationVariables = Exact<{
  name: Scalars['String']['input'];
}>;


export type CreateGroupMutation = { __typename?: 'MutationRoot', groupCreate: { __typename?: 'Group', id: string } };

export type GroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type GroupsQuery = { __typename?: 'QueryRoot', currentUser: { __typename?: 'User', id: string, groups: Array<{ __typename?: 'Group', id: string, name: string, creationDate: string }> } };

export type AllProjectsStatsQueryVariables = Exact<{ [key: string]: never; }>;


export type AllProjectsStatsQuery = { __typename?: 'QueryRoot', projects: Array<{ __typename?: 'Project', id: string, name: string, creationDate: string, devices: { __typename?: 'DeviceConnection', edges: Array<{ __typename?: 'DeviceEdge', node: { __typename?: 'Device', id: string, creationDate: string } }> } }> };

export type CreateProjectMutationVariables = Exact<{
  name: Scalars['String']['input'];
  owner?: InputMaybe<Scalars['UUID']['input']>;
}>;


export type CreateProjectMutation = { __typename?: 'MutationRoot', projectCreate: { __typename?: 'Project', id: string } };

export type ProjectStatsQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
}>;


export type ProjectStatsQuery = { __typename?: 'QueryRoot', project: { __typename?: 'Project', devices: { __typename?: 'DeviceConnection', edges: Array<{ __typename?: 'DeviceEdge', cursor: string }>, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean }, nodes: Array<{ __typename?: 'Device', run: { __typename?: 'Run', changeDate: string, runStatus: StepStatus } }> }, chips: Array<{ __typename?: 'Chip', id: string, name: string, nvmSize?: number | null, binaries: Array<{ __typename?: 'Binary', analysis?: { __typename?: 'BinaryAnalysis', nvmSize: number } | null }>, binaryNewest?: { __typename?: 'Binary', analysis?: { __typename?: 'BinaryAnalysis', nvmSize: number } | null } | null }> } };

export type ProjectsQueryVariables = Exact<{ [key: string]: never; }>;


export type ProjectsQuery = { __typename?: 'QueryRoot', currentUser: { __typename?: 'User', id: string, projects: Array<{ __typename?: 'Project', id: string, name: string, owner: { __typename: 'Group', name: string } | { __typename: 'User' }, chips: Array<{ __typename?: 'Chip', id: string, partNumber: string, name: string }>, devices: { __typename?: 'DeviceConnection', numDevices: number, edges: Array<{ __typename?: 'DeviceEdge', node: { __typename?: 'Device', id: string, creationDate: string } }> } }> } };

export type BinaryQueryVariables = Exact<{
  projectId: Scalars['UUID']['input'];
  id: Scalars['UUID']['input'];
  chipId: Scalars['UUID']['input'];
}>;


export type BinaryQuery = { __typename?: 'QueryRoot', project: { __typename?: 'Project', chip: { __typename?: 'Chip', id: string, partNumber: string, nvmSize?: number | null, name: string, binary: { __typename?: 'Binary', id: string, active: boolean, version: string, creationDate: string, deletionDate?: string | null, parts: Array<{ __typename?: 'BinaryPart', id: string, kind: BinaryKind }>, analysis?: { __typename?: 'BinaryAnalysis', nvmSize: number } | null } } } };

export type DeleteBinaryMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;


export type DeleteBinaryMutation = { __typename?: 'MutationRoot', binaryDelete: string };

export type CreateBinaryMutationVariables = Exact<{
  chipId: Scalars['UUID']['input'];
  version: Scalars['String']['input'];
  parts: Array<InsertBinaryPart> | InsertBinaryPart;
}>;


export type CreateBinaryMutation = { __typename?: 'MutationRoot', binaryCreate: { __typename?: 'Binary', id: string } };

export type RenameBlockSchemaMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
  name: Scalars['String']['input'];
}>;


export type RenameBlockSchemaMutation = { __typename?: 'MutationRoot', blockSchemaRename: string };

export type SetIdentifierBlockSchemaMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
  identifier: Scalars['Boolean']['input'];
}>;


export type SetIdentifierBlockSchemaMutation = { __typename?: 'MutationRoot', blockSchemaSetIdentifier: string };

export type DeleteBlockSchemaMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;


export type DeleteBlockSchemaMutation = { __typename?: 'MutationRoot', blockSchemaDelete: string };

export type LoadBlockSchemaQueryVariables = Exact<{
  projectId: Scalars['UUID']['input'];
  id: Scalars['UUID']['input'];
}>;


export type LoadBlockSchemaQuery = { __typename?: 'QueryRoot', project: { __typename?: 'Project', blockSchema: { __typename?: 'BlockSchema', id: string, active: boolean, name: string, displayName: string, isDeviceIdentifier: boolean, schema: string, generator?: string | null, creationDate: string, deletionDate?: string | null, memorySource?: { __typename?: 'MemoryEndpoint', id: string, address: bigint, length: number, chip: { __typename?: 'Chip', id: string, partNumber: string, name: string } } | null, memorySinks: Array<{ __typename?: 'MemoryEndpoint', id: string, address: bigint, length: number, chip: { __typename?: 'Chip', id: string, partNumber: string, name: string } }> } } };

export type CreateBlockSchemaMutationVariables = Exact<{
  projectId: Scalars['UUID']['input'];
  name: Scalars['String']['input'];
  schema: Scalars['String']['input'];
  generator?: InputMaybe<Scalars['String']['input']>;
  memorySource?: InputMaybe<ChipEndpoint>;
  memorySinks: Array<ChipEndpoint> | ChipEndpoint;
  isDeviceIdentifier: Scalars['Boolean']['input'];
}>;


export type CreateBlockSchemaMutation = { __typename?: 'MutationRoot', blockSchemaCreate: { __typename?: 'BlockSchema', id: string, name: string, displayName: string, schema: string, creationDate: string } };

export type BlockSchemasQueryVariables = Exact<{
  projectId: Scalars['UUID']['input'];
}>;


export type BlockSchemasQuery = { __typename?: 'QueryRoot', project: { __typename?: 'Project', id: string, blockSchemas: Array<{ __typename?: 'BlockSchema', id: string, name: string, displayName: string, active: boolean, isDeviceIdentifier: boolean, supportsRequirements: boolean }> } };

export type ChipQueryVariables = Exact<{
  projectId: Scalars['UUID']['input'];
  id: Scalars['UUID']['input'];
}>;


export type ChipQuery = { __typename?: 'QueryRoot', project: { __typename?: 'Project', id: string, chip: { __typename?: 'Chip', id: string, name: string, partNumber: string, active: boolean, nvmSize?: number | null, creationDate: string, deletionDate?: string | null, binaryNewest?: { __typename?: 'Binary', id: string, versionMajor: number, versionMinor: number, versionPatch: number, parts: Array<{ __typename?: 'BinaryPart', id: string }>, analysis?: { __typename?: 'BinaryAnalysis', nvmSize: number } | null } | null } } };

export type DeleteChipMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;


export type DeleteChipMutation = { __typename?: 'MutationRoot', chipDelete: string };

export type CreateChipMutationVariables = Exact<{
  projectId: Scalars['UUID']['input'];
  identifier: Scalars['String']['input'];
  chipName: Scalars['String']['input'];
}>;


export type CreateChipMutation = { __typename?: 'MutationRoot', chipCreate: { __typename?: 'Chip', id: string } };

export type AvailableTargetsQueryVariables = Exact<{ [key: string]: never; }>;


export type AvailableTargetsQuery = { __typename?: 'QueryRoot', availableTargets: Array<{ __typename?: 'Target', name: string }> };

export type ChipsQueryVariables = Exact<{
  projectId: Scalars['UUID']['input'];
}>;


export type ChipsQuery = { __typename?: 'QueryRoot', project: { __typename?: 'Project', id: string, chips: Array<{ __typename?: 'Chip', id: string, isSupported: boolean, name: string, partNumber: string, nvmSize?: number | null, binaries: Array<{ __typename?: 'Binary', id: string, version: string, parts: Array<{ __typename?: 'BinaryPart', id: string }>, analysis?: { __typename?: 'BinaryAnalysis', nvmSize: number } | null }> }> } };

export type AttachmentPartsFragment = { __typename?: 'Attachment', id: string, name: string };

export type BlockPartsFragment = { __typename?: 'Block', id: string, dataDecoded: Record<string, unknown>, schema: { __typename?: 'BlockSchema', id: string, displayName: string } };

export type BlockSchemaPartsFragment = { __typename?: 'BlockSchema', id: string, displayName: string };

export type LogPartsFragment = { __typename?: 'Log', id: string, level: string, message: string, creationDate: string };

export type LoadDeviceQueryVariables = Exact<{
  projectId: Scalars['UUID']['input'];
  deviceId: Scalars['UUID']['input'];
  runId?: InputMaybe<Scalars['UUID']['input']>;
}>;


export type LoadDeviceQuery = { __typename?: 'QueryRoot', project: { __typename?: 'Project', id: string, device: { __typename?: 'Device', id: string, name?: string | null, active: boolean, deletionDate?: string | null, runs: Array<{ __typename?: 'Run', id: string, station: string, runStatus: StepStatus }>, run: { __typename?: 'Run', station: string, creationDate: string, changeDate: string, id: string, blockSchemas: Array<{ __typename?: 'BlockSchema', id: string, displayName: string }>, provisioner?: { __typename?: 'Provisioner', id: string, name: string } | null, logs: Array<{ __typename?: 'Log', id: string, level: string, message: string, creationDate: string }>, attachments: Array<{ __typename?: 'Attachment', id: string, name: string }>, blocks: Array<{ __typename?: 'Block', id: string, dataDecoded: Record<string, unknown>, schema: { __typename?: 'BlockSchema', id: string, displayName: string } }>, requirementChecks: Array<{ __typename?: 'RequirementCheck', passed: boolean, requirement: { __typename?: 'Requirement', id: string, description?: string | null, name: string, schema: { __typename?: 'BlockSchema', id: string, displayName: string } }, block?: { __typename?: 'Block', id: string, dataDecoded: Record<string, unknown> } | null }>, steps: Array<{ __typename?: 'RunStep', id: string, stepId: string, station: string, status: StepStatus, changeDate: string, data: { __typename?: 'RunStepData', binary?: { __typename?: 'Binary', id: string, version: string } | null }, step: { __typename?: 'Step', id: string, kind: { __typename: 'DataBlock', blockSchema: { __typename?: 'BlockSchema', displayName: string } } | { __typename: 'FlashChip', chip: { __typename?: 'Chip', id: string, name: string, partNumber: string } } | { __typename: 'RunCommand', command?: string | null, directory?: string | null } } }> } } } };

export type DeleteDeviceMutationVariables = Exact<{
  deviceId: Scalars['UUID']['input'];
}>;


export type DeleteDeviceMutation = { __typename?: 'MutationRoot', deviceDelete: string };

export type LogEventSubscriptionVariables = Exact<{
  token: Scalars['String']['input'];
  runId: Scalars['UUID']['input'];
}>;


export type LogEventSubscription = { __typename?: 'SubscriptionRoot', logEvent: { __typename?: 'Log', id: string, level: string, message: string, creationDate: string } };

export type DevicesQueryVariables = Exact<{
  projectId: Scalars['UUID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Scalars['String']['input']>;
}>;


export type DevicesQuery = { __typename?: 'QueryRoot', project: { __typename?: 'Project', id: string, devices: { __typename?: 'DeviceConnection', numDevices: number, nodes: Array<{ __typename?: 'Device', id: string, name?: string | null, creationDate: string, changeDate: string, run: { __typename?: 'Run', id: string, runStatus: StepStatus, station: string } }> } } };

export type RequirementCheckPartsFragment = { __typename?: 'RequirementCheck', passed: boolean, requirement: { __typename?: 'Requirement', id: string, description?: string | null, name: string, schema: { __typename?: 'BlockSchema', displayName: string } }, block?: { __typename?: 'Block', id: string, dataDecoded: Record<string, unknown> } | null };

export type StepPartsFragment = { __typename?: 'RunStep', id: string, stepId: string, station: string, status: StepStatus, changeDate: string, data: { __typename?: 'RunStepData', binary?: { __typename?: 'Binary', id: string, version: string } | null }, step: { __typename?: 'Step', id: string, kind: { __typename: 'DataBlock', blockSchema: { __typename?: 'BlockSchema', displayName: string } } | { __typename: 'FlashChip', chip: { __typename?: 'Chip', id: string, name: string, partNumber: string } } | { __typename: 'RunCommand', command?: string | null, directory?: string | null } } };

export type SomeDataBlockFragment = { __typename?: 'DataBlock', blockSchema: { __typename?: 'BlockSchema', displayName: string } };

export type SomeFlashChipFragment = { __typename?: 'FlashChip', chip: { __typename?: 'Chip', id: string, name: string, partNumber: string } };

export type SomeRunCommandFragment = { __typename?: 'RunCommand', command?: string | null, directory?: string | null };

export type PlaybookInfoQueryVariables = Exact<{
  projectId: Scalars['UUID']['input'];
}>;


export type PlaybookInfoQuery = { __typename?: 'QueryRoot', project: { __typename?: 'Project', id: string, chips: Array<{ __typename?: 'Chip', id: string, name: string, isSupported: boolean, partNumber: string }>, blockSchemas: Array<{ __typename?: 'BlockSchema', id: string, name: string }>, playbooks: Array<{ __typename?: 'Playbook', version?: number | null, stations: Array<{ __typename?: 'Station', name: string, steps: Array<{ __typename?: 'Step', id: string, label?: string | null, kind: { __typename: 'DataBlock', blockSchema: { __typename?: 'BlockSchema', id: string, displayName: string, name: string } } | { __typename: 'FlashChip', command?: string | null, chip: { __typename?: 'Chip', id: string, isSupported: boolean } } | { __typename: 'RunCommand', command?: string | null, directory?: string | null } }> }> }> } };

export type UpdatePlaybookMutationVariables = Exact<{
  projectId: Scalars['UUID']['input'];
  playbook: InputPlaybook;
}>;


export type UpdatePlaybookMutation = { __typename?: 'MutationRoot', playbookUpdate: boolean };

export type SavePlaybookMutationVariables = Exact<{
  projectId: Scalars['UUID']['input'];
}>;


export type SavePlaybookMutation = { __typename?: 'MutationRoot', playbookSave: number };

export type PlaybookFromYamlMutationVariables = Exact<{
  projectId: Scalars['UUID']['input'];
  yaml: Scalars['String']['input'];
}>;


export type PlaybookFromYamlMutation = { __typename?: 'MutationRoot', playbookFromYaml: number };

export type LoadProvisionerQueryVariables = Exact<{
  project_id: Scalars['UUID']['input'];
  id: Scalars['UUID']['input'];
}>;


export type LoadProvisionerQuery = { __typename?: 'QueryRoot', project: { __typename?: 'Project', id: string, provisioner: { __typename?: 'Provisioner', id: string, name: string, station: string, active: boolean, token?: string | null, creationDate: string, changeDate: string, currentDevice?: { __typename?: 'Device', id: string, name?: string | null } | null } } };

export type RegenerateProvisionerTokenMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;


export type RegenerateProvisionerTokenMutation = { __typename?: 'MutationRoot', provisionerRegenerateToken: { __typename?: 'Provisioner', id: string, name: string, station: string, active: boolean, token?: string | null, creationDate: string, changeDate: string, currentDevice?: { __typename?: 'Device', id: string, name?: string | null } | null } };

export type DeleteProvisionerMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;


export type DeleteProvisionerMutation = { __typename?: 'MutationRoot', provisionerDelete: string };

export type RenameProvisionerMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
  name: Scalars['String']['input'];
}>;


export type RenameProvisionerMutation = { __typename?: 'MutationRoot', provisionerRename: { __typename?: 'Provisioner', id: string, name: string, station: string, active: boolean, token?: string | null, creationDate: string, changeDate: string, currentDevice?: { __typename?: 'Device', id: string, name?: string | null } | null } };

export type AssignStationToProvisionerMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
  station: Scalars['String']['input'];
}>;


export type AssignStationToProvisionerMutation = { __typename?: 'MutationRoot', provisionerAssignStation: { __typename?: 'Provisioner', id: string, name: string, station: string, active: boolean, token?: string | null, creationDate: string, changeDate: string, currentDevice?: { __typename?: 'Device', id: string, name?: string | null } | null } };

export type CreateProvisionerMutationVariables = Exact<{
  projectId: Scalars['UUID']['input'];
  name: Scalars['String']['input'];
  station: Scalars['String']['input'];
}>;


export type CreateProvisionerMutation = { __typename?: 'MutationRoot', provisionerCreate: { __typename?: 'Provisioner', id: string, name: string, station: string, active: boolean, token?: string | null, creationDate: string, changeDate: string, currentDevice?: { __typename?: 'Device', id: string } | null } };

export type PlaybookQueryVariables = Exact<{
  projectId: Scalars['UUID']['input'];
}>;


export type PlaybookQuery = { __typename?: 'QueryRoot', project: { __typename?: 'Project', id: string, playbook: { __typename?: 'Playbook', version?: number | null, stations: Array<{ __typename?: 'Station', name: string }> } } };

export type ProvisionersQueryVariables = Exact<{
  projectId: Scalars['UUID']['input'];
}>;


export type ProvisionersQuery = { __typename?: 'QueryRoot', project: { __typename?: 'Project', id: string, provisioners: Array<{ __typename?: 'Provisioner', id: string, name: string, token?: string | null, active: boolean, currentDevice?: { __typename?: 'Device', id: string } | null }> } };

export type LoadRequirementQueryVariables = Exact<{
  projectId: Scalars['UUID']['input'];
  requirementId: Scalars['UUID']['input'];
}>;


export type LoadRequirementQuery = { __typename?: 'QueryRoot', project: { __typename?: 'Project', id: string, requirement: { __typename?: 'Requirement', id: string, name: string, description?: string | null, active: boolean, creationDate: string, deletionDate?: string | null, upperLimit?: number | null, lowerLimit?: number | null, inclusiveLower: boolean, inclusiveUpper: boolean, schema: { __typename?: 'BlockSchema', id: string, name: string } } } };

export type DeleteRequirementMutationVariables = Exact<{
  requirementId: Scalars['UUID']['input'];
}>;


export type DeleteRequirementMutation = { __typename?: 'MutationRoot', requirementDelete: string };

export type CreateRequirementMutationVariables = Exact<{
  projectId: Scalars['UUID']['input'];
  schemaId: Scalars['UUID']['input'];
  name: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  lowerLimit?: InputMaybe<Scalars['Float']['input']>;
  inclusiveLower: Scalars['Boolean']['input'];
  upperLimit?: InputMaybe<Scalars['Float']['input']>;
  inclusiveUpper: Scalars['Boolean']['input'];
}>;


export type CreateRequirementMutation = { __typename?: 'MutationRoot', requirementCreate: { __typename?: 'Requirement', id: string } };

export type RequirementsQueryVariables = Exact<{
  projectId: Scalars['UUID']['input'];
}>;


export type RequirementsQuery = { __typename?: 'QueryRoot', project: { __typename?: 'Project', id: string, requirements: Array<{ __typename?: 'Requirement', id: string, name: string, active: boolean, description?: string | null, creationDate: string }> } };

export type MoveProjectMutationVariables = Exact<{
  projectId: Scalars['UUID']['input'];
  newOwner: Scalars['UUID']['input'];
}>;


export type MoveProjectMutation = { __typename?: 'MutationRoot', projectTransfer: string };

export type DeleteProjectMutationVariables = Exact<{
  projectId: Scalars['UUID']['input'];
}>;


export type DeleteProjectMutation = { __typename?: 'MutationRoot', projectDelete: string };

export type ProjectData2QueryVariables = Exact<{
  projectId: Scalars['UUID']['input'];
}>;


export type ProjectData2Query = { __typename?: 'QueryRoot', project: { __typename?: 'Project', name: string, creationDate: string, deletionDate?: string | null, active: boolean, settings: { __typename?: 'ProjectSettings', integrations: { __typename?: 'IntegrationsSettings', matrix: { __typename?: 'MatrixSettings', rooms: Array<string> }, telegram: { __typename?: 'TelegramSettings', chatIds: Array<number> } } } } };

export type UpdateProjectSettingsMutationVariables = Exact<{
  projectId: Scalars['UUID']['input'];
  settings: InputProjectSettings;
}>;


export type UpdateProjectSettingsMutation = { __typename?: 'MutationRoot', projectSettingsUpdate: string };

export type ProjectNameQueryVariables = Exact<{
  projectId: Scalars['UUID']['input'];
}>;


export type ProjectNameQuery = { __typename?: 'QueryRoot', project: { __typename?: 'Project', name: string, creationDate: string, deletionDate?: string | null, active: boolean, owner: { __typename: 'Group', name: string, id: string } | { __typename: 'User', email: string, id: string } } };

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = { __typename?: 'QueryRoot', currentUser: { __typename?: 'User', id: string, admin: boolean, email: string } };

export type RefreshMutationVariables = Exact<{
  token: Scalars['String']['input'];
  impersonate?: InputMaybe<Scalars['UUID']['input']>;
}>;


export type RefreshMutation = { __typename?: 'MutationRoot', refresh: string };

export type PingQueryVariables = Exact<{ [key: string]: never; }>;


export type PingQuery = { __typename?: 'QueryRoot', ping: boolean };

export type ConfigQueryVariables = Exact<{ [key: string]: never; }>;


export type ConfigQuery = { __typename?: 'QueryRoot', config: { __typename?: 'AppConfig', name: string, apiUrl: string, frontendUrl: string } };

export const AttachmentPartsFragmentDoc = gql`
    fragment AttachmentParts on Attachment {
  id
  name
}
    `;
export const BlockPartsFragmentDoc = gql`
    fragment BlockParts on Block {
  id
  schema {
    id
    displayName
  }
  dataDecoded
}
    `;
export const BlockSchemaPartsFragmentDoc = gql`
    fragment BlockSchemaParts on BlockSchema {
  id
  displayName
}
    `;
export const LogPartsFragmentDoc = gql`
    fragment LogParts on Log {
  id
  level
  message
  creationDate
}
    `;
export const RequirementCheckPartsFragmentDoc = gql`
    fragment RequirementCheckParts on RequirementCheck {
  requirement {
    id
    description
    name
    schema {
      displayName
    }
  }
  block {
    id
    dataDecoded
  }
  passed
}
    `;
export const SomeDataBlockFragmentDoc = gql`
    fragment SomeDataBlock on DataBlock {
  blockSchema {
    displayName
  }
}
    `;
export const SomeFlashChipFragmentDoc = gql`
    fragment SomeFlashChip on FlashChip {
  chip {
    id
    name
    partNumber
  }
}
    `;
export const SomeRunCommandFragmentDoc = gql`
    fragment SomeRunCommand on RunCommand {
  command
  directory
}
    `;
export const StepPartsFragmentDoc = gql`
    fragment StepParts on RunStep {
  id
  stepId
  station
  status
  data {
    binary {
      id
      version
    }
  }
  step {
    id
    kind {
      __typename
      ...SomeDataBlock
      ...SomeFlashChip
      ...SomeRunCommand
    }
  }
  changeDate
}
    ${SomeDataBlockFragmentDoc}
${SomeFlashChipFragmentDoc}
${SomeRunCommandFragmentDoc}`;
export const Current_UserDocument = gql`
    query current_user {
  currentUser {
    email
  }
}
    `;

/**
 * __useCurrent_UserQuery__
 *
 * To run a query within a React component, call `useCurrent_UserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrent_UserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrent_UserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrent_UserQuery(baseOptions?: Apollo.QueryHookOptions<Current_UserQuery, Current_UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Current_UserQuery, Current_UserQueryVariables>(Current_UserDocument, options);
      }
export function useCurrent_UserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Current_UserQuery, Current_UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Current_UserQuery, Current_UserQueryVariables>(Current_UserDocument, options);
        }
export function useCurrent_UserSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Current_UserQuery, Current_UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Current_UserQuery, Current_UserQueryVariables>(Current_UserDocument, options);
        }
export type Current_UserQueryHookResult = ReturnType<typeof useCurrent_UserQuery>;
export type Current_UserLazyQueryHookResult = ReturnType<typeof useCurrent_UserLazyQuery>;
export type Current_UserSuspenseQueryHookResult = ReturnType<typeof useCurrent_UserSuspenseQuery>;
export type Current_UserQueryResult = Apollo.QueryResult<Current_UserQuery, Current_UserQueryVariables>;
export const SignInDocument = gql`
    mutation SignIn($email: String!, $password: String!) {
  signIn(email: $email, password: $password)
}
    `;
export type SignInMutationFn = Apollo.MutationFunction<SignInMutation, SignInMutationVariables>;

/**
 * __useSignInMutation__
 *
 * To run a mutation, you first call `useSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInMutation, { data, loading, error }] = useSignInMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useSignInMutation(baseOptions?: Apollo.MutationHookOptions<SignInMutation, SignInMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignInMutation, SignInMutationVariables>(SignInDocument, options);
      }
export type SignInMutationHookResult = ReturnType<typeof useSignInMutation>;
export type SignInMutationResult = Apollo.MutationResult<SignInMutation>;
export type SignInMutationOptions = Apollo.BaseMutationOptions<SignInMutation, SignInMutationVariables>;
export const SignInWithOAuthDocument = gql`
    mutation SignInWithOAuth($provider: String!, $code: String!, $nonce: String) {
  signInWithOauth(provider: $provider, code: $code, nonce: $nonce)
}
    `;
export type SignInWithOAuthMutationFn = Apollo.MutationFunction<SignInWithOAuthMutation, SignInWithOAuthMutationVariables>;

/**
 * __useSignInWithOAuthMutation__
 *
 * To run a mutation, you first call `useSignInWithOAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInWithOAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInWithOAuthMutation, { data, loading, error }] = useSignInWithOAuthMutation({
 *   variables: {
 *      provider: // value for 'provider'
 *      code: // value for 'code'
 *      nonce: // value for 'nonce'
 *   },
 * });
 */
export function useSignInWithOAuthMutation(baseOptions?: Apollo.MutationHookOptions<SignInWithOAuthMutation, SignInWithOAuthMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignInWithOAuthMutation, SignInWithOAuthMutationVariables>(SignInWithOAuthDocument, options);
      }
export type SignInWithOAuthMutationHookResult = ReturnType<typeof useSignInWithOAuthMutation>;
export type SignInWithOAuthMutationResult = Apollo.MutationResult<SignInWithOAuthMutation>;
export type SignInWithOAuthMutationOptions = Apollo.BaseMutationOptions<SignInWithOAuthMutation, SignInWithOAuthMutationVariables>;
export const OAuthUrlDocument = gql`
    query OAuthUrl($provider: String!) {
  oauthUrl(provider: $provider) {
    authUrl
    nonce
  }
}
    `;

/**
 * __useOAuthUrlQuery__
 *
 * To run a query within a React component, call `useOAuthUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useOAuthUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOAuthUrlQuery({
 *   variables: {
 *      provider: // value for 'provider'
 *   },
 * });
 */
export function useOAuthUrlQuery(baseOptions: Apollo.QueryHookOptions<OAuthUrlQuery, OAuthUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OAuthUrlQuery, OAuthUrlQueryVariables>(OAuthUrlDocument, options);
      }
export function useOAuthUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OAuthUrlQuery, OAuthUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OAuthUrlQuery, OAuthUrlQueryVariables>(OAuthUrlDocument, options);
        }
export function useOAuthUrlSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OAuthUrlQuery, OAuthUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OAuthUrlQuery, OAuthUrlQueryVariables>(OAuthUrlDocument, options);
        }
export type OAuthUrlQueryHookResult = ReturnType<typeof useOAuthUrlQuery>;
export type OAuthUrlLazyQueryHookResult = ReturnType<typeof useOAuthUrlLazyQuery>;
export type OAuthUrlSuspenseQueryHookResult = ReturnType<typeof useOAuthUrlSuspenseQuery>;
export type OAuthUrlQueryResult = Apollo.QueryResult<OAuthUrlQuery, OAuthUrlQueryVariables>;
export const ReleasesDocument = gql`
    query Releases {
  provisionerReleases {
    ui {
      name
      version
      releaseNotes
      artifacts {
        name
        url
      }
    }
  }
}
    `;

/**
 * __useReleasesQuery__
 *
 * To run a query within a React component, call `useReleasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReleasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReleasesQuery({
 *   variables: {
 *   },
 * });
 */
export function useReleasesQuery(baseOptions?: Apollo.QueryHookOptions<ReleasesQuery, ReleasesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReleasesQuery, ReleasesQueryVariables>(ReleasesDocument, options);
      }
export function useReleasesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReleasesQuery, ReleasesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReleasesQuery, ReleasesQueryVariables>(ReleasesDocument, options);
        }
export function useReleasesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ReleasesQuery, ReleasesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReleasesQuery, ReleasesQueryVariables>(ReleasesDocument, options);
        }
export type ReleasesQueryHookResult = ReturnType<typeof useReleasesQuery>;
export type ReleasesLazyQueryHookResult = ReturnType<typeof useReleasesLazyQuery>;
export type ReleasesSuspenseQueryHookResult = ReturnType<typeof useReleasesSuspenseQuery>;
export type ReleasesQueryResult = Apollo.QueryResult<ReleasesQuery, ReleasesQueryVariables>;
export const UserDocument = gql`
    query User {
  currentUser {
    id
    email
    creationDate
    userType
    groups {
      id
      name
    }
  }
}
    `;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserQuery(baseOptions?: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export function useUserSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserSuspenseQueryHookResult = ReturnType<typeof useUserSuspenseQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const ChangePasswordDocument = gql`
    mutation ChangePassword($currentPassword: String!, $newPassword: String!) {
  changePassword(currentPassword: $currentPassword, newPassword: $newPassword)
}
    `;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      currentPassword: // value for 'currentPassword'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const ConfirmUserDocument = gql`
    mutation ConfirmUser($token: String!) {
  userConfirm(token: $token)
}
    `;
export type ConfirmUserMutationFn = Apollo.MutationFunction<ConfirmUserMutation, ConfirmUserMutationVariables>;

/**
 * __useConfirmUserMutation__
 *
 * To run a mutation, you first call `useConfirmUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmUserMutation, { data, loading, error }] = useConfirmUserMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useConfirmUserMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmUserMutation, ConfirmUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmUserMutation, ConfirmUserMutationVariables>(ConfirmUserDocument, options);
      }
export type ConfirmUserMutationHookResult = ReturnType<typeof useConfirmUserMutation>;
export type ConfirmUserMutationResult = Apollo.MutationResult<ConfirmUserMutation>;
export type ConfirmUserMutationOptions = Apollo.BaseMutationOptions<ConfirmUserMutation, ConfirmUserMutationVariables>;
export const ResendConfirmationEmailDocument = gql`
    mutation ResendConfirmationEmail($email: String!) {
  userResendConfirmCode(email: $email)
}
    `;
export type ResendConfirmationEmailMutationFn = Apollo.MutationFunction<ResendConfirmationEmailMutation, ResendConfirmationEmailMutationVariables>;

/**
 * __useResendConfirmationEmailMutation__
 *
 * To run a mutation, you first call `useResendConfirmationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendConfirmationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendConfirmationEmailMutation, { data, loading, error }] = useResendConfirmationEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResendConfirmationEmailMutation(baseOptions?: Apollo.MutationHookOptions<ResendConfirmationEmailMutation, ResendConfirmationEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendConfirmationEmailMutation, ResendConfirmationEmailMutationVariables>(ResendConfirmationEmailDocument, options);
      }
export type ResendConfirmationEmailMutationHookResult = ReturnType<typeof useResendConfirmationEmailMutation>;
export type ResendConfirmationEmailMutationResult = Apollo.MutationResult<ResendConfirmationEmailMutation>;
export type ResendConfirmationEmailMutationOptions = Apollo.BaseMutationOptions<ResendConfirmationEmailMutation, ResendConfirmationEmailMutationVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($email: String!, $password: String!) {
  userCreate(email: $email, password: $password) {
    id
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const StripeDataDocument = gql`
    query stripeData {
  stripeData {
    publicKey
  }
}
    `;

/**
 * __useStripeDataQuery__
 *
 * To run a query within a React component, call `useStripeDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useStripeDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStripeDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useStripeDataQuery(baseOptions?: Apollo.QueryHookOptions<StripeDataQuery, StripeDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StripeDataQuery, StripeDataQueryVariables>(StripeDataDocument, options);
      }
export function useStripeDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StripeDataQuery, StripeDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StripeDataQuery, StripeDataQueryVariables>(StripeDataDocument, options);
        }
export function useStripeDataSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<StripeDataQuery, StripeDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<StripeDataQuery, StripeDataQueryVariables>(StripeDataDocument, options);
        }
export type StripeDataQueryHookResult = ReturnType<typeof useStripeDataQuery>;
export type StripeDataLazyQueryHookResult = ReturnType<typeof useStripeDataLazyQuery>;
export type StripeDataSuspenseQueryHookResult = ReturnType<typeof useStripeDataSuspenseQuery>;
export type StripeDataQueryResult = Apollo.QueryResult<StripeDataQuery, StripeDataQueryVariables>;
export const UsersPendingApprovalDocument = gql`
    query usersPendingApproval {
  usersPendingApproval {
    id
    email
    creationDate
  }
}
    `;

/**
 * __useUsersPendingApprovalQuery__
 *
 * To run a query within a React component, call `useUsersPendingApprovalQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersPendingApprovalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersPendingApprovalQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersPendingApprovalQuery(baseOptions?: Apollo.QueryHookOptions<UsersPendingApprovalQuery, UsersPendingApprovalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersPendingApprovalQuery, UsersPendingApprovalQueryVariables>(UsersPendingApprovalDocument, options);
      }
export function useUsersPendingApprovalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersPendingApprovalQuery, UsersPendingApprovalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersPendingApprovalQuery, UsersPendingApprovalQueryVariables>(UsersPendingApprovalDocument, options);
        }
export function useUsersPendingApprovalSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UsersPendingApprovalQuery, UsersPendingApprovalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UsersPendingApprovalQuery, UsersPendingApprovalQueryVariables>(UsersPendingApprovalDocument, options);
        }
export type UsersPendingApprovalQueryHookResult = ReturnType<typeof useUsersPendingApprovalQuery>;
export type UsersPendingApprovalLazyQueryHookResult = ReturnType<typeof useUsersPendingApprovalLazyQuery>;
export type UsersPendingApprovalSuspenseQueryHookResult = ReturnType<typeof useUsersPendingApprovalSuspenseQuery>;
export type UsersPendingApprovalQueryResult = Apollo.QueryResult<UsersPendingApprovalQuery, UsersPendingApprovalQueryVariables>;
export const AllUsersDocument = gql`
    query allUsers {
  users {
    id
    email
  }
}
    `;

/**
 * __useAllUsersQuery__
 *
 * To run a query within a React component, call `useAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllUsersQuery(baseOptions?: Apollo.QueryHookOptions<AllUsersQuery, AllUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllUsersQuery, AllUsersQueryVariables>(AllUsersDocument, options);
      }
export function useAllUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllUsersQuery, AllUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllUsersQuery, AllUsersQueryVariables>(AllUsersDocument, options);
        }
export function useAllUsersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AllUsersQuery, AllUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AllUsersQuery, AllUsersQueryVariables>(AllUsersDocument, options);
        }
export type AllUsersQueryHookResult = ReturnType<typeof useAllUsersQuery>;
export type AllUsersLazyQueryHookResult = ReturnType<typeof useAllUsersLazyQuery>;
export type AllUsersSuspenseQueryHookResult = ReturnType<typeof useAllUsersSuspenseQuery>;
export type AllUsersQueryResult = Apollo.QueryResult<AllUsersQuery, AllUsersQueryVariables>;
export const UserApproveDocument = gql`
    mutation userApprove($id: UUID!) {
  userApprove(id: $id)
}
    `;
export type UserApproveMutationFn = Apollo.MutationFunction<UserApproveMutation, UserApproveMutationVariables>;

/**
 * __useUserApproveMutation__
 *
 * To run a mutation, you first call `useUserApproveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserApproveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userApproveMutation, { data, loading, error }] = useUserApproveMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserApproveMutation(baseOptions?: Apollo.MutationHookOptions<UserApproveMutation, UserApproveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserApproveMutation, UserApproveMutationVariables>(UserApproveDocument, options);
      }
export type UserApproveMutationHookResult = ReturnType<typeof useUserApproveMutation>;
export type UserApproveMutationResult = Apollo.MutationResult<UserApproveMutation>;
export type UserApproveMutationOptions = Apollo.BaseMutationOptions<UserApproveMutation, UserApproveMutationVariables>;
export const RefreshWithImpersonationDocument = gql`
    mutation refreshWithImpersonation($token: String!, $impersonate: UUID) {
  refresh(token: $token, impersonate: $impersonate)
}
    `;
export type RefreshWithImpersonationMutationFn = Apollo.MutationFunction<RefreshWithImpersonationMutation, RefreshWithImpersonationMutationVariables>;

/**
 * __useRefreshWithImpersonationMutation__
 *
 * To run a mutation, you first call `useRefreshWithImpersonationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshWithImpersonationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshWithImpersonationMutation, { data, loading, error }] = useRefreshWithImpersonationMutation({
 *   variables: {
 *      token: // value for 'token'
 *      impersonate: // value for 'impersonate'
 *   },
 * });
 */
export function useRefreshWithImpersonationMutation(baseOptions?: Apollo.MutationHookOptions<RefreshWithImpersonationMutation, RefreshWithImpersonationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshWithImpersonationMutation, RefreshWithImpersonationMutationVariables>(RefreshWithImpersonationDocument, options);
      }
export type RefreshWithImpersonationMutationHookResult = ReturnType<typeof useRefreshWithImpersonationMutation>;
export type RefreshWithImpersonationMutationResult = Apollo.MutationResult<RefreshWithImpersonationMutation>;
export type RefreshWithImpersonationMutationOptions = Apollo.BaseMutationOptions<RefreshWithImpersonationMutation, RefreshWithImpersonationMutationVariables>;
export const FamiliesDocument = gql`
    query Families {
  currentUser {
    id
    families {
      id
      definition
    }
  }
}
    `;

/**
 * __useFamiliesQuery__
 *
 * To run a query within a React component, call `useFamiliesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFamiliesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFamiliesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFamiliesQuery(baseOptions?: Apollo.QueryHookOptions<FamiliesQuery, FamiliesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FamiliesQuery, FamiliesQueryVariables>(FamiliesDocument, options);
      }
export function useFamiliesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FamiliesQuery, FamiliesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FamiliesQuery, FamiliesQueryVariables>(FamiliesDocument, options);
        }
export function useFamiliesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FamiliesQuery, FamiliesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FamiliesQuery, FamiliesQueryVariables>(FamiliesDocument, options);
        }
export type FamiliesQueryHookResult = ReturnType<typeof useFamiliesQuery>;
export type FamiliesLazyQueryHookResult = ReturnType<typeof useFamiliesLazyQuery>;
export type FamiliesSuspenseQueryHookResult = ReturnType<typeof useFamiliesSuspenseQuery>;
export type FamiliesQueryResult = Apollo.QueryResult<FamiliesQuery, FamiliesQueryVariables>;
export const FamilyDocument = gql`
    query Family($id: UUID!) {
  family(id: $id) {
    id
    definition
  }
}
    `;

/**
 * __useFamilyQuery__
 *
 * To run a query within a React component, call `useFamilyQuery` and pass it any options that fit your needs.
 * When your component renders, `useFamilyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFamilyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFamilyQuery(baseOptions: Apollo.QueryHookOptions<FamilyQuery, FamilyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FamilyQuery, FamilyQueryVariables>(FamilyDocument, options);
      }
export function useFamilyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FamilyQuery, FamilyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FamilyQuery, FamilyQueryVariables>(FamilyDocument, options);
        }
export function useFamilySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FamilyQuery, FamilyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FamilyQuery, FamilyQueryVariables>(FamilyDocument, options);
        }
export type FamilyQueryHookResult = ReturnType<typeof useFamilyQuery>;
export type FamilyLazyQueryHookResult = ReturnType<typeof useFamilyLazyQuery>;
export type FamilySuspenseQueryHookResult = ReturnType<typeof useFamilySuspenseQuery>;
export type FamilyQueryResult = Apollo.QueryResult<FamilyQuery, FamilyQueryVariables>;
export const CreateFamilyDocument = gql`
    mutation CreateFamily($definition: String!) {
  familyCreate(definition: $definition) {
    id
  }
}
    `;
export type CreateFamilyMutationFn = Apollo.MutationFunction<CreateFamilyMutation, CreateFamilyMutationVariables>;

/**
 * __useCreateFamilyMutation__
 *
 * To run a mutation, you first call `useCreateFamilyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFamilyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFamilyMutation, { data, loading, error }] = useCreateFamilyMutation({
 *   variables: {
 *      definition: // value for 'definition'
 *   },
 * });
 */
export function useCreateFamilyMutation(baseOptions?: Apollo.MutationHookOptions<CreateFamilyMutation, CreateFamilyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFamilyMutation, CreateFamilyMutationVariables>(CreateFamilyDocument, options);
      }
export type CreateFamilyMutationHookResult = ReturnType<typeof useCreateFamilyMutation>;
export type CreateFamilyMutationResult = Apollo.MutationResult<CreateFamilyMutation>;
export type CreateFamilyMutationOptions = Apollo.BaseMutationOptions<CreateFamilyMutation, CreateFamilyMutationVariables>;
export const GroupDocument = gql`
    query Group($id: UUID!) {
  group(id: $id) {
    name
    creationDate
    projects {
      id
      name
    }
    users {
      id
      email
    }
  }
}
    `;

/**
 * __useGroupQuery__
 *
 * To run a query within a React component, call `useGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGroupQuery(baseOptions: Apollo.QueryHookOptions<GroupQuery, GroupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GroupQuery, GroupQueryVariables>(GroupDocument, options);
      }
export function useGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GroupQuery, GroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GroupQuery, GroupQueryVariables>(GroupDocument, options);
        }
export function useGroupSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GroupQuery, GroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GroupQuery, GroupQueryVariables>(GroupDocument, options);
        }
export type GroupQueryHookResult = ReturnType<typeof useGroupQuery>;
export type GroupLazyQueryHookResult = ReturnType<typeof useGroupLazyQuery>;
export type GroupSuspenseQueryHookResult = ReturnType<typeof useGroupSuspenseQuery>;
export type GroupQueryResult = Apollo.QueryResult<GroupQuery, GroupQueryVariables>;
export const GroupAddUserDocument = gql`
    mutation GroupAddUser($group_id: UUID!, $user_email: String!) {
  groupAddUser(groupId: $group_id, userEmail: $user_email)
}
    `;
export type GroupAddUserMutationFn = Apollo.MutationFunction<GroupAddUserMutation, GroupAddUserMutationVariables>;

/**
 * __useGroupAddUserMutation__
 *
 * To run a mutation, you first call `useGroupAddUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGroupAddUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [groupAddUserMutation, { data, loading, error }] = useGroupAddUserMutation({
 *   variables: {
 *      group_id: // value for 'group_id'
 *      user_email: // value for 'user_email'
 *   },
 * });
 */
export function useGroupAddUserMutation(baseOptions?: Apollo.MutationHookOptions<GroupAddUserMutation, GroupAddUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GroupAddUserMutation, GroupAddUserMutationVariables>(GroupAddUserDocument, options);
      }
export type GroupAddUserMutationHookResult = ReturnType<typeof useGroupAddUserMutation>;
export type GroupAddUserMutationResult = Apollo.MutationResult<GroupAddUserMutation>;
export type GroupAddUserMutationOptions = Apollo.BaseMutationOptions<GroupAddUserMutation, GroupAddUserMutationVariables>;
export const GroupRemoveUserDocument = gql`
    mutation GroupRemoveUser($group_id: UUID!, $user_id: UUID!) {
  groupRemoveUser(groupId: $group_id, userId: $user_id)
}
    `;
export type GroupRemoveUserMutationFn = Apollo.MutationFunction<GroupRemoveUserMutation, GroupRemoveUserMutationVariables>;

/**
 * __useGroupRemoveUserMutation__
 *
 * To run a mutation, you first call `useGroupRemoveUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGroupRemoveUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [groupRemoveUserMutation, { data, loading, error }] = useGroupRemoveUserMutation({
 *   variables: {
 *      group_id: // value for 'group_id'
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useGroupRemoveUserMutation(baseOptions?: Apollo.MutationHookOptions<GroupRemoveUserMutation, GroupRemoveUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GroupRemoveUserMutation, GroupRemoveUserMutationVariables>(GroupRemoveUserDocument, options);
      }
export type GroupRemoveUserMutationHookResult = ReturnType<typeof useGroupRemoveUserMutation>;
export type GroupRemoveUserMutationResult = Apollo.MutationResult<GroupRemoveUserMutation>;
export type GroupRemoveUserMutationOptions = Apollo.BaseMutationOptions<GroupRemoveUserMutation, GroupRemoveUserMutationVariables>;
export const CreateGroupDocument = gql`
    mutation CreateGroup($name: String!) {
  groupCreate(name: $name) {
    id
  }
}
    `;
export type CreateGroupMutationFn = Apollo.MutationFunction<CreateGroupMutation, CreateGroupMutationVariables>;

/**
 * __useCreateGroupMutation__
 *
 * To run a mutation, you first call `useCreateGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGroupMutation, { data, loading, error }] = useCreateGroupMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateGroupMutation(baseOptions?: Apollo.MutationHookOptions<CreateGroupMutation, CreateGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateGroupMutation, CreateGroupMutationVariables>(CreateGroupDocument, options);
      }
export type CreateGroupMutationHookResult = ReturnType<typeof useCreateGroupMutation>;
export type CreateGroupMutationResult = Apollo.MutationResult<CreateGroupMutation>;
export type CreateGroupMutationOptions = Apollo.BaseMutationOptions<CreateGroupMutation, CreateGroupMutationVariables>;
export const GroupsDocument = gql`
    query Groups {
  currentUser {
    id
    groups {
      id
      name
      creationDate
    }
  }
}
    `;

/**
 * __useGroupsQuery__
 *
 * To run a query within a React component, call `useGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGroupsQuery(baseOptions?: Apollo.QueryHookOptions<GroupsQuery, GroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GroupsQuery, GroupsQueryVariables>(GroupsDocument, options);
      }
export function useGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GroupsQuery, GroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GroupsQuery, GroupsQueryVariables>(GroupsDocument, options);
        }
export function useGroupsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GroupsQuery, GroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GroupsQuery, GroupsQueryVariables>(GroupsDocument, options);
        }
export type GroupsQueryHookResult = ReturnType<typeof useGroupsQuery>;
export type GroupsLazyQueryHookResult = ReturnType<typeof useGroupsLazyQuery>;
export type GroupsSuspenseQueryHookResult = ReturnType<typeof useGroupsSuspenseQuery>;
export type GroupsQueryResult = Apollo.QueryResult<GroupsQuery, GroupsQueryVariables>;
export const AllProjectsStatsDocument = gql`
    query allProjectsStats {
  projects {
    id
    name
    creationDate
    devices {
      edges {
        node {
          id
          creationDate
        }
      }
    }
  }
}
    `;

/**
 * __useAllProjectsStatsQuery__
 *
 * To run a query within a React component, call `useAllProjectsStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllProjectsStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllProjectsStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllProjectsStatsQuery(baseOptions?: Apollo.QueryHookOptions<AllProjectsStatsQuery, AllProjectsStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllProjectsStatsQuery, AllProjectsStatsQueryVariables>(AllProjectsStatsDocument, options);
      }
export function useAllProjectsStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllProjectsStatsQuery, AllProjectsStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllProjectsStatsQuery, AllProjectsStatsQueryVariables>(AllProjectsStatsDocument, options);
        }
export function useAllProjectsStatsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AllProjectsStatsQuery, AllProjectsStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AllProjectsStatsQuery, AllProjectsStatsQueryVariables>(AllProjectsStatsDocument, options);
        }
export type AllProjectsStatsQueryHookResult = ReturnType<typeof useAllProjectsStatsQuery>;
export type AllProjectsStatsLazyQueryHookResult = ReturnType<typeof useAllProjectsStatsLazyQuery>;
export type AllProjectsStatsSuspenseQueryHookResult = ReturnType<typeof useAllProjectsStatsSuspenseQuery>;
export type AllProjectsStatsQueryResult = Apollo.QueryResult<AllProjectsStatsQuery, AllProjectsStatsQueryVariables>;
export const CreateProjectDocument = gql`
    mutation CreateProject($name: String!, $owner: UUID) {
  projectCreate(name: $name, groupOwner: $owner) {
    id
  }
}
    `;
export type CreateProjectMutationFn = Apollo.MutationFunction<CreateProjectMutation, CreateProjectMutationVariables>;

/**
 * __useCreateProjectMutation__
 *
 * To run a mutation, you first call `useCreateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectMutation, { data, loading, error }] = useCreateProjectMutation({
 *   variables: {
 *      name: // value for 'name'
 *      owner: // value for 'owner'
 *   },
 * });
 */
export function useCreateProjectMutation(baseOptions?: Apollo.MutationHookOptions<CreateProjectMutation, CreateProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProjectMutation, CreateProjectMutationVariables>(CreateProjectDocument, options);
      }
export type CreateProjectMutationHookResult = ReturnType<typeof useCreateProjectMutation>;
export type CreateProjectMutationResult = Apollo.MutationResult<CreateProjectMutation>;
export type CreateProjectMutationOptions = Apollo.BaseMutationOptions<CreateProjectMutation, CreateProjectMutationVariables>;
export const ProjectStatsDocument = gql`
    query ProjectStats($id: UUID!, $after: String) {
  project(id: $id) {
    devices(after: $after) {
      edges {
        cursor
      }
      pageInfo {
        hasNextPage
      }
      nodes {
        run {
          changeDate
          runStatus
        }
      }
    }
    chips {
      id
      name
      nvmSize
      binaries {
        analysis {
          nvmSize
        }
      }
      binaryNewest {
        analysis {
          nvmSize
        }
      }
    }
  }
}
    `;

/**
 * __useProjectStatsQuery__
 *
 * To run a query within a React component, call `useProjectStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectStatsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useProjectStatsQuery(baseOptions: Apollo.QueryHookOptions<ProjectStatsQuery, ProjectStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectStatsQuery, ProjectStatsQueryVariables>(ProjectStatsDocument, options);
      }
export function useProjectStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectStatsQuery, ProjectStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectStatsQuery, ProjectStatsQueryVariables>(ProjectStatsDocument, options);
        }
export function useProjectStatsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ProjectStatsQuery, ProjectStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProjectStatsQuery, ProjectStatsQueryVariables>(ProjectStatsDocument, options);
        }
export type ProjectStatsQueryHookResult = ReturnType<typeof useProjectStatsQuery>;
export type ProjectStatsLazyQueryHookResult = ReturnType<typeof useProjectStatsLazyQuery>;
export type ProjectStatsSuspenseQueryHookResult = ReturnType<typeof useProjectStatsSuspenseQuery>;
export type ProjectStatsQueryResult = Apollo.QueryResult<ProjectStatsQuery, ProjectStatsQueryVariables>;
export const ProjectsDocument = gql`
    query Projects {
  currentUser {
    id
    projects {
      id
      name
      owner {
        __typename
        ... on Group {
          name
        }
      }
      chips {
        id
        partNumber
        name
      }
      devices {
        edges {
          node {
            id
            creationDate
          }
        }
        numDevices
      }
    }
  }
}
    `;

/**
 * __useProjectsQuery__
 *
 * To run a query within a React component, call `useProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useProjectsQuery(baseOptions?: Apollo.QueryHookOptions<ProjectsQuery, ProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectsQuery, ProjectsQueryVariables>(ProjectsDocument, options);
      }
export function useProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectsQuery, ProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectsQuery, ProjectsQueryVariables>(ProjectsDocument, options);
        }
export function useProjectsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ProjectsQuery, ProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProjectsQuery, ProjectsQueryVariables>(ProjectsDocument, options);
        }
export type ProjectsQueryHookResult = ReturnType<typeof useProjectsQuery>;
export type ProjectsLazyQueryHookResult = ReturnType<typeof useProjectsLazyQuery>;
export type ProjectsSuspenseQueryHookResult = ReturnType<typeof useProjectsSuspenseQuery>;
export type ProjectsQueryResult = Apollo.QueryResult<ProjectsQuery, ProjectsQueryVariables>;
export const BinaryDocument = gql`
    query Binary($projectId: UUID!, $id: UUID!, $chipId: UUID!) {
  project(id: $projectId) {
    chip(id: $chipId) {
      id
      partNumber
      nvmSize
      name
      binary(id: $id) {
        id
        active
        version
        creationDate
        deletionDate
        parts {
          id
          kind
        }
        analysis {
          nvmSize
        }
      }
    }
  }
}
    `;

/**
 * __useBinaryQuery__
 *
 * To run a query within a React component, call `useBinaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useBinaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBinaryQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      id: // value for 'id'
 *      chipId: // value for 'chipId'
 *   },
 * });
 */
export function useBinaryQuery(baseOptions: Apollo.QueryHookOptions<BinaryQuery, BinaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BinaryQuery, BinaryQueryVariables>(BinaryDocument, options);
      }
export function useBinaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BinaryQuery, BinaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BinaryQuery, BinaryQueryVariables>(BinaryDocument, options);
        }
export function useBinarySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BinaryQuery, BinaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BinaryQuery, BinaryQueryVariables>(BinaryDocument, options);
        }
export type BinaryQueryHookResult = ReturnType<typeof useBinaryQuery>;
export type BinaryLazyQueryHookResult = ReturnType<typeof useBinaryLazyQuery>;
export type BinarySuspenseQueryHookResult = ReturnType<typeof useBinarySuspenseQuery>;
export type BinaryQueryResult = Apollo.QueryResult<BinaryQuery, BinaryQueryVariables>;
export const DeleteBinaryDocument = gql`
    mutation DeleteBinary($id: UUID!) {
  binaryDelete(id: $id)
}
    `;
export type DeleteBinaryMutationFn = Apollo.MutationFunction<DeleteBinaryMutation, DeleteBinaryMutationVariables>;

/**
 * __useDeleteBinaryMutation__
 *
 * To run a mutation, you first call `useDeleteBinaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBinaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBinaryMutation, { data, loading, error }] = useDeleteBinaryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBinaryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBinaryMutation, DeleteBinaryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBinaryMutation, DeleteBinaryMutationVariables>(DeleteBinaryDocument, options);
      }
export type DeleteBinaryMutationHookResult = ReturnType<typeof useDeleteBinaryMutation>;
export type DeleteBinaryMutationResult = Apollo.MutationResult<DeleteBinaryMutation>;
export type DeleteBinaryMutationOptions = Apollo.BaseMutationOptions<DeleteBinaryMutation, DeleteBinaryMutationVariables>;
export const CreateBinaryDocument = gql`
    mutation CreateBinary($chipId: UUID!, $version: String!, $parts: [InsertBinaryPart!]!) {
  binaryCreate(chipId: $chipId, version: $version, parts: $parts) {
    id
  }
}
    `;
export type CreateBinaryMutationFn = Apollo.MutationFunction<CreateBinaryMutation, CreateBinaryMutationVariables>;

/**
 * __useCreateBinaryMutation__
 *
 * To run a mutation, you first call `useCreateBinaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBinaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBinaryMutation, { data, loading, error }] = useCreateBinaryMutation({
 *   variables: {
 *      chipId: // value for 'chipId'
 *      version: // value for 'version'
 *      parts: // value for 'parts'
 *   },
 * });
 */
export function useCreateBinaryMutation(baseOptions?: Apollo.MutationHookOptions<CreateBinaryMutation, CreateBinaryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBinaryMutation, CreateBinaryMutationVariables>(CreateBinaryDocument, options);
      }
export type CreateBinaryMutationHookResult = ReturnType<typeof useCreateBinaryMutation>;
export type CreateBinaryMutationResult = Apollo.MutationResult<CreateBinaryMutation>;
export type CreateBinaryMutationOptions = Apollo.BaseMutationOptions<CreateBinaryMutation, CreateBinaryMutationVariables>;
export const RenameBlockSchemaDocument = gql`
    mutation RenameBlockSchema($id: UUID!, $name: String!) {
  blockSchemaRename(id: $id, name: $name)
}
    `;
export type RenameBlockSchemaMutationFn = Apollo.MutationFunction<RenameBlockSchemaMutation, RenameBlockSchemaMutationVariables>;

/**
 * __useRenameBlockSchemaMutation__
 *
 * To run a mutation, you first call `useRenameBlockSchemaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameBlockSchemaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameBlockSchemaMutation, { data, loading, error }] = useRenameBlockSchemaMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useRenameBlockSchemaMutation(baseOptions?: Apollo.MutationHookOptions<RenameBlockSchemaMutation, RenameBlockSchemaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RenameBlockSchemaMutation, RenameBlockSchemaMutationVariables>(RenameBlockSchemaDocument, options);
      }
export type RenameBlockSchemaMutationHookResult = ReturnType<typeof useRenameBlockSchemaMutation>;
export type RenameBlockSchemaMutationResult = Apollo.MutationResult<RenameBlockSchemaMutation>;
export type RenameBlockSchemaMutationOptions = Apollo.BaseMutationOptions<RenameBlockSchemaMutation, RenameBlockSchemaMutationVariables>;
export const SetIdentifierBlockSchemaDocument = gql`
    mutation SetIdentifierBlockSchema($id: UUID!, $identifier: Boolean!) {
  blockSchemaSetIdentifier(id: $id, identifier: $identifier)
}
    `;
export type SetIdentifierBlockSchemaMutationFn = Apollo.MutationFunction<SetIdentifierBlockSchemaMutation, SetIdentifierBlockSchemaMutationVariables>;

/**
 * __useSetIdentifierBlockSchemaMutation__
 *
 * To run a mutation, you first call `useSetIdentifierBlockSchemaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetIdentifierBlockSchemaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setIdentifierBlockSchemaMutation, { data, loading, error }] = useSetIdentifierBlockSchemaMutation({
 *   variables: {
 *      id: // value for 'id'
 *      identifier: // value for 'identifier'
 *   },
 * });
 */
export function useSetIdentifierBlockSchemaMutation(baseOptions?: Apollo.MutationHookOptions<SetIdentifierBlockSchemaMutation, SetIdentifierBlockSchemaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetIdentifierBlockSchemaMutation, SetIdentifierBlockSchemaMutationVariables>(SetIdentifierBlockSchemaDocument, options);
      }
export type SetIdentifierBlockSchemaMutationHookResult = ReturnType<typeof useSetIdentifierBlockSchemaMutation>;
export type SetIdentifierBlockSchemaMutationResult = Apollo.MutationResult<SetIdentifierBlockSchemaMutation>;
export type SetIdentifierBlockSchemaMutationOptions = Apollo.BaseMutationOptions<SetIdentifierBlockSchemaMutation, SetIdentifierBlockSchemaMutationVariables>;
export const DeleteBlockSchemaDocument = gql`
    mutation DeleteBlockSchema($id: UUID!) {
  blockSchemaDelete(id: $id)
}
    `;
export type DeleteBlockSchemaMutationFn = Apollo.MutationFunction<DeleteBlockSchemaMutation, DeleteBlockSchemaMutationVariables>;

/**
 * __useDeleteBlockSchemaMutation__
 *
 * To run a mutation, you first call `useDeleteBlockSchemaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBlockSchemaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBlockSchemaMutation, { data, loading, error }] = useDeleteBlockSchemaMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBlockSchemaMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBlockSchemaMutation, DeleteBlockSchemaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBlockSchemaMutation, DeleteBlockSchemaMutationVariables>(DeleteBlockSchemaDocument, options);
      }
export type DeleteBlockSchemaMutationHookResult = ReturnType<typeof useDeleteBlockSchemaMutation>;
export type DeleteBlockSchemaMutationResult = Apollo.MutationResult<DeleteBlockSchemaMutation>;
export type DeleteBlockSchemaMutationOptions = Apollo.BaseMutationOptions<DeleteBlockSchemaMutation, DeleteBlockSchemaMutationVariables>;
export const LoadBlockSchemaDocument = gql`
    query LoadBlockSchema($projectId: UUID!, $id: UUID!) {
  project(id: $projectId) {
    blockSchema(id: $id) {
      id
      active
      name
      displayName
      isDeviceIdentifier
      schema
      generator
      memorySource {
        id
        address
        length
        chip {
          id
          partNumber
          name
        }
      }
      memorySinks {
        id
        address
        length
        chip {
          id
          partNumber
          name
        }
      }
      creationDate
      deletionDate
    }
  }
}
    `;

/**
 * __useLoadBlockSchemaQuery__
 *
 * To run a query within a React component, call `useLoadBlockSchemaQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadBlockSchemaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadBlockSchemaQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLoadBlockSchemaQuery(baseOptions: Apollo.QueryHookOptions<LoadBlockSchemaQuery, LoadBlockSchemaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoadBlockSchemaQuery, LoadBlockSchemaQueryVariables>(LoadBlockSchemaDocument, options);
      }
export function useLoadBlockSchemaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoadBlockSchemaQuery, LoadBlockSchemaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoadBlockSchemaQuery, LoadBlockSchemaQueryVariables>(LoadBlockSchemaDocument, options);
        }
export function useLoadBlockSchemaSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LoadBlockSchemaQuery, LoadBlockSchemaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LoadBlockSchemaQuery, LoadBlockSchemaQueryVariables>(LoadBlockSchemaDocument, options);
        }
export type LoadBlockSchemaQueryHookResult = ReturnType<typeof useLoadBlockSchemaQuery>;
export type LoadBlockSchemaLazyQueryHookResult = ReturnType<typeof useLoadBlockSchemaLazyQuery>;
export type LoadBlockSchemaSuspenseQueryHookResult = ReturnType<typeof useLoadBlockSchemaSuspenseQuery>;
export type LoadBlockSchemaQueryResult = Apollo.QueryResult<LoadBlockSchemaQuery, LoadBlockSchemaQueryVariables>;
export const CreateBlockSchemaDocument = gql`
    mutation CreateBlockSchema($projectId: UUID!, $name: String!, $schema: String!, $generator: String, $memorySource: ChipEndpoint, $memorySinks: [ChipEndpoint!]!, $isDeviceIdentifier: Boolean!) {
  blockSchemaCreate(
    projectId: $projectId
    name: $name
    schema: $schema
    generator: $generator
    memorySource: $memorySource
    memorySinks: $memorySinks
    isDeviceIdentifier: $isDeviceIdentifier
  ) {
    id
    name
    displayName
    schema
    creationDate
  }
}
    `;
export type CreateBlockSchemaMutationFn = Apollo.MutationFunction<CreateBlockSchemaMutation, CreateBlockSchemaMutationVariables>;

/**
 * __useCreateBlockSchemaMutation__
 *
 * To run a mutation, you first call `useCreateBlockSchemaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBlockSchemaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBlockSchemaMutation, { data, loading, error }] = useCreateBlockSchemaMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      name: // value for 'name'
 *      schema: // value for 'schema'
 *      generator: // value for 'generator'
 *      memorySource: // value for 'memorySource'
 *      memorySinks: // value for 'memorySinks'
 *      isDeviceIdentifier: // value for 'isDeviceIdentifier'
 *   },
 * });
 */
export function useCreateBlockSchemaMutation(baseOptions?: Apollo.MutationHookOptions<CreateBlockSchemaMutation, CreateBlockSchemaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBlockSchemaMutation, CreateBlockSchemaMutationVariables>(CreateBlockSchemaDocument, options);
      }
export type CreateBlockSchemaMutationHookResult = ReturnType<typeof useCreateBlockSchemaMutation>;
export type CreateBlockSchemaMutationResult = Apollo.MutationResult<CreateBlockSchemaMutation>;
export type CreateBlockSchemaMutationOptions = Apollo.BaseMutationOptions<CreateBlockSchemaMutation, CreateBlockSchemaMutationVariables>;
export const BlockSchemasDocument = gql`
    query BlockSchemas($projectId: UUID!) {
  project(id: $projectId) {
    id
    blockSchemas {
      id
      name
      displayName
      active
      isDeviceIdentifier
      supportsRequirements
    }
  }
}
    `;

/**
 * __useBlockSchemasQuery__
 *
 * To run a query within a React component, call `useBlockSchemasQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlockSchemasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlockSchemasQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useBlockSchemasQuery(baseOptions: Apollo.QueryHookOptions<BlockSchemasQuery, BlockSchemasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BlockSchemasQuery, BlockSchemasQueryVariables>(BlockSchemasDocument, options);
      }
export function useBlockSchemasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BlockSchemasQuery, BlockSchemasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BlockSchemasQuery, BlockSchemasQueryVariables>(BlockSchemasDocument, options);
        }
export function useBlockSchemasSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BlockSchemasQuery, BlockSchemasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BlockSchemasQuery, BlockSchemasQueryVariables>(BlockSchemasDocument, options);
        }
export type BlockSchemasQueryHookResult = ReturnType<typeof useBlockSchemasQuery>;
export type BlockSchemasLazyQueryHookResult = ReturnType<typeof useBlockSchemasLazyQuery>;
export type BlockSchemasSuspenseQueryHookResult = ReturnType<typeof useBlockSchemasSuspenseQuery>;
export type BlockSchemasQueryResult = Apollo.QueryResult<BlockSchemasQuery, BlockSchemasQueryVariables>;
export const ChipDocument = gql`
    query Chip($projectId: UUID!, $id: UUID!) {
  project(id: $projectId) {
    id
    chip(id: $id) {
      id
      name
      partNumber
      active
      nvmSize
      creationDate
      deletionDate
      binaryNewest {
        id
        versionMajor
        versionMinor
        versionPatch
        parts {
          id
        }
        analysis {
          nvmSize
        }
      }
    }
  }
}
    `;

/**
 * __useChipQuery__
 *
 * To run a query within a React component, call `useChipQuery` and pass it any options that fit your needs.
 * When your component renders, `useChipQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChipQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useChipQuery(baseOptions: Apollo.QueryHookOptions<ChipQuery, ChipQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChipQuery, ChipQueryVariables>(ChipDocument, options);
      }
export function useChipLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChipQuery, ChipQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChipQuery, ChipQueryVariables>(ChipDocument, options);
        }
export function useChipSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ChipQuery, ChipQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ChipQuery, ChipQueryVariables>(ChipDocument, options);
        }
export type ChipQueryHookResult = ReturnType<typeof useChipQuery>;
export type ChipLazyQueryHookResult = ReturnType<typeof useChipLazyQuery>;
export type ChipSuspenseQueryHookResult = ReturnType<typeof useChipSuspenseQuery>;
export type ChipQueryResult = Apollo.QueryResult<ChipQuery, ChipQueryVariables>;
export const DeleteChipDocument = gql`
    mutation DeleteChip($id: UUID!) {
  chipDelete(id: $id)
}
    `;
export type DeleteChipMutationFn = Apollo.MutationFunction<DeleteChipMutation, DeleteChipMutationVariables>;

/**
 * __useDeleteChipMutation__
 *
 * To run a mutation, you first call `useDeleteChipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteChipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteChipMutation, { data, loading, error }] = useDeleteChipMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteChipMutation(baseOptions?: Apollo.MutationHookOptions<DeleteChipMutation, DeleteChipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteChipMutation, DeleteChipMutationVariables>(DeleteChipDocument, options);
      }
export type DeleteChipMutationHookResult = ReturnType<typeof useDeleteChipMutation>;
export type DeleteChipMutationResult = Apollo.MutationResult<DeleteChipMutation>;
export type DeleteChipMutationOptions = Apollo.BaseMutationOptions<DeleteChipMutation, DeleteChipMutationVariables>;
export const CreateChipDocument = gql`
    mutation CreateChip($projectId: UUID!, $identifier: String!, $chipName: String!) {
  chipCreate(projectId: $projectId, name: $identifier, partNumber: $chipName) {
    id
  }
}
    `;
export type CreateChipMutationFn = Apollo.MutationFunction<CreateChipMutation, CreateChipMutationVariables>;

/**
 * __useCreateChipMutation__
 *
 * To run a mutation, you first call `useCreateChipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChipMutation, { data, loading, error }] = useCreateChipMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      identifier: // value for 'identifier'
 *      chipName: // value for 'chipName'
 *   },
 * });
 */
export function useCreateChipMutation(baseOptions?: Apollo.MutationHookOptions<CreateChipMutation, CreateChipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateChipMutation, CreateChipMutationVariables>(CreateChipDocument, options);
      }
export type CreateChipMutationHookResult = ReturnType<typeof useCreateChipMutation>;
export type CreateChipMutationResult = Apollo.MutationResult<CreateChipMutation>;
export type CreateChipMutationOptions = Apollo.BaseMutationOptions<CreateChipMutation, CreateChipMutationVariables>;
export const AvailableTargetsDocument = gql`
    query AvailableTargets {
  availableTargets {
    name
  }
}
    `;

/**
 * __useAvailableTargetsQuery__
 *
 * To run a query within a React component, call `useAvailableTargetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableTargetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableTargetsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAvailableTargetsQuery(baseOptions?: Apollo.QueryHookOptions<AvailableTargetsQuery, AvailableTargetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AvailableTargetsQuery, AvailableTargetsQueryVariables>(AvailableTargetsDocument, options);
      }
export function useAvailableTargetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AvailableTargetsQuery, AvailableTargetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AvailableTargetsQuery, AvailableTargetsQueryVariables>(AvailableTargetsDocument, options);
        }
export function useAvailableTargetsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AvailableTargetsQuery, AvailableTargetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AvailableTargetsQuery, AvailableTargetsQueryVariables>(AvailableTargetsDocument, options);
        }
export type AvailableTargetsQueryHookResult = ReturnType<typeof useAvailableTargetsQuery>;
export type AvailableTargetsLazyQueryHookResult = ReturnType<typeof useAvailableTargetsLazyQuery>;
export type AvailableTargetsSuspenseQueryHookResult = ReturnType<typeof useAvailableTargetsSuspenseQuery>;
export type AvailableTargetsQueryResult = Apollo.QueryResult<AvailableTargetsQuery, AvailableTargetsQueryVariables>;
export const ChipsDocument = gql`
    query Chips($projectId: UUID!) {
  project(id: $projectId) {
    id
    chips {
      id
      isSupported
      name
      partNumber
      nvmSize
      binaries {
        id
        version
        parts {
          id
        }
        analysis {
          nvmSize
        }
      }
    }
  }
}
    `;

/**
 * __useChipsQuery__
 *
 * To run a query within a React component, call `useChipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useChipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChipsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useChipsQuery(baseOptions: Apollo.QueryHookOptions<ChipsQuery, ChipsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChipsQuery, ChipsQueryVariables>(ChipsDocument, options);
      }
export function useChipsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChipsQuery, ChipsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChipsQuery, ChipsQueryVariables>(ChipsDocument, options);
        }
export function useChipsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ChipsQuery, ChipsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ChipsQuery, ChipsQueryVariables>(ChipsDocument, options);
        }
export type ChipsQueryHookResult = ReturnType<typeof useChipsQuery>;
export type ChipsLazyQueryHookResult = ReturnType<typeof useChipsLazyQuery>;
export type ChipsSuspenseQueryHookResult = ReturnType<typeof useChipsSuspenseQuery>;
export type ChipsQueryResult = Apollo.QueryResult<ChipsQuery, ChipsQueryVariables>;
export const LoadDeviceDocument = gql`
    query LoadDevice($projectId: UUID!, $deviceId: UUID!, $runId: UUID) {
  project(id: $projectId) {
    id
    device(id: $deviceId) {
      id
      name
      active
      deletionDate
      runs {
        id
        station
        runStatus
      }
      run(id: $runId) {
        station
        creationDate
        changeDate
        blockSchemas {
          id
          displayName
        }
        provisioner {
          id
          name
        }
        id
        logs {
          id
          level
          message
          creationDate
        }
        attachments {
          id
          name
        }
        blocks {
          id
          schema {
            id
            displayName
          }
          dataDecoded
        }
        requirementChecks {
          requirement {
            id
            description
            name
            schema {
              id
              displayName
            }
          }
          block {
            id
            dataDecoded
          }
          passed
        }
        steps {
          ...StepParts
        }
      }
    }
  }
}
    ${StepPartsFragmentDoc}`;

/**
 * __useLoadDeviceQuery__
 *
 * To run a query within a React component, call `useLoadDeviceQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadDeviceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadDeviceQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      deviceId: // value for 'deviceId'
 *      runId: // value for 'runId'
 *   },
 * });
 */
export function useLoadDeviceQuery(baseOptions: Apollo.QueryHookOptions<LoadDeviceQuery, LoadDeviceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoadDeviceQuery, LoadDeviceQueryVariables>(LoadDeviceDocument, options);
      }
export function useLoadDeviceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoadDeviceQuery, LoadDeviceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoadDeviceQuery, LoadDeviceQueryVariables>(LoadDeviceDocument, options);
        }
export function useLoadDeviceSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LoadDeviceQuery, LoadDeviceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LoadDeviceQuery, LoadDeviceQueryVariables>(LoadDeviceDocument, options);
        }
export type LoadDeviceQueryHookResult = ReturnType<typeof useLoadDeviceQuery>;
export type LoadDeviceLazyQueryHookResult = ReturnType<typeof useLoadDeviceLazyQuery>;
export type LoadDeviceSuspenseQueryHookResult = ReturnType<typeof useLoadDeviceSuspenseQuery>;
export type LoadDeviceQueryResult = Apollo.QueryResult<LoadDeviceQuery, LoadDeviceQueryVariables>;
export const DeleteDeviceDocument = gql`
    mutation DeleteDevice($deviceId: UUID!) {
  deviceDelete(id: $deviceId)
}
    `;
export type DeleteDeviceMutationFn = Apollo.MutationFunction<DeleteDeviceMutation, DeleteDeviceMutationVariables>;

/**
 * __useDeleteDeviceMutation__
 *
 * To run a mutation, you first call `useDeleteDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDeviceMutation, { data, loading, error }] = useDeleteDeviceMutation({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *   },
 * });
 */
export function useDeleteDeviceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDeviceMutation, DeleteDeviceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDeviceMutation, DeleteDeviceMutationVariables>(DeleteDeviceDocument, options);
      }
export type DeleteDeviceMutationHookResult = ReturnType<typeof useDeleteDeviceMutation>;
export type DeleteDeviceMutationResult = Apollo.MutationResult<DeleteDeviceMutation>;
export type DeleteDeviceMutationOptions = Apollo.BaseMutationOptions<DeleteDeviceMutation, DeleteDeviceMutationVariables>;
export const LogEventDocument = gql`
    subscription LogEvent($token: String!, $runId: UUID!) {
  logEvent(token: $token, runId: $runId) {
    id
    level
    message
    creationDate
  }
}
    `;

/**
 * __useLogEventSubscription__
 *
 * To run a query within a React component, call `useLogEventSubscription` and pass it any options that fit your needs.
 * When your component renders, `useLogEventSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLogEventSubscription({
 *   variables: {
 *      token: // value for 'token'
 *      runId: // value for 'runId'
 *   },
 * });
 */
export function useLogEventSubscription(baseOptions: Apollo.SubscriptionHookOptions<LogEventSubscription, LogEventSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<LogEventSubscription, LogEventSubscriptionVariables>(LogEventDocument, options);
      }
export type LogEventSubscriptionHookResult = ReturnType<typeof useLogEventSubscription>;
export type LogEventSubscriptionResult = Apollo.SubscriptionResult<LogEventSubscription>;
export const DevicesDocument = gql`
    query Devices($projectId: UUID!, $first: Int, $after: String, $filter: String) {
  project(id: $projectId) {
    id
    devices(after: $after, first: $first, filter: $filter) {
      numDevices
      nodes {
        id
        name
        creationDate
        changeDate
        run {
          id
          runStatus
          station
        }
      }
    }
  }
}
    `;

/**
 * __useDevicesQuery__
 *
 * To run a query within a React component, call `useDevicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDevicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDevicesQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDevicesQuery(baseOptions: Apollo.QueryHookOptions<DevicesQuery, DevicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DevicesQuery, DevicesQueryVariables>(DevicesDocument, options);
      }
export function useDevicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DevicesQuery, DevicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DevicesQuery, DevicesQueryVariables>(DevicesDocument, options);
        }
export function useDevicesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DevicesQuery, DevicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DevicesQuery, DevicesQueryVariables>(DevicesDocument, options);
        }
export type DevicesQueryHookResult = ReturnType<typeof useDevicesQuery>;
export type DevicesLazyQueryHookResult = ReturnType<typeof useDevicesLazyQuery>;
export type DevicesSuspenseQueryHookResult = ReturnType<typeof useDevicesSuspenseQuery>;
export type DevicesQueryResult = Apollo.QueryResult<DevicesQuery, DevicesQueryVariables>;
export const PlaybookInfoDocument = gql`
    query PlaybookInfo($projectId: UUID!) {
  project(id: $projectId) {
    id
    chips {
      id
      name
      isSupported
      partNumber
    }
    blockSchemas {
      id
      name
    }
    playbooks {
      version
      stations {
        name
        steps {
          id
          label
          kind {
            __typename
            ... on DataBlock {
              blockSchema {
                id
                displayName
                name
              }
            }
            ... on FlashChip {
              command
              chip {
                id
                isSupported
              }
            }
            ... on RunCommand {
              command
              directory
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __usePlaybookInfoQuery__
 *
 * To run a query within a React component, call `usePlaybookInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlaybookInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlaybookInfoQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function usePlaybookInfoQuery(baseOptions: Apollo.QueryHookOptions<PlaybookInfoQuery, PlaybookInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlaybookInfoQuery, PlaybookInfoQueryVariables>(PlaybookInfoDocument, options);
      }
export function usePlaybookInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlaybookInfoQuery, PlaybookInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlaybookInfoQuery, PlaybookInfoQueryVariables>(PlaybookInfoDocument, options);
        }
export function usePlaybookInfoSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PlaybookInfoQuery, PlaybookInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PlaybookInfoQuery, PlaybookInfoQueryVariables>(PlaybookInfoDocument, options);
        }
export type PlaybookInfoQueryHookResult = ReturnType<typeof usePlaybookInfoQuery>;
export type PlaybookInfoLazyQueryHookResult = ReturnType<typeof usePlaybookInfoLazyQuery>;
export type PlaybookInfoSuspenseQueryHookResult = ReturnType<typeof usePlaybookInfoSuspenseQuery>;
export type PlaybookInfoQueryResult = Apollo.QueryResult<PlaybookInfoQuery, PlaybookInfoQueryVariables>;
export const UpdatePlaybookDocument = gql`
    mutation UpdatePlaybook($projectId: UUID!, $playbook: InputPlaybook!) {
  playbookUpdate(projectId: $projectId, playbook: $playbook)
}
    `;
export type UpdatePlaybookMutationFn = Apollo.MutationFunction<UpdatePlaybookMutation, UpdatePlaybookMutationVariables>;

/**
 * __useUpdatePlaybookMutation__
 *
 * To run a mutation, you first call `useUpdatePlaybookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlaybookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlaybookMutation, { data, loading, error }] = useUpdatePlaybookMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      playbook: // value for 'playbook'
 *   },
 * });
 */
export function useUpdatePlaybookMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePlaybookMutation, UpdatePlaybookMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePlaybookMutation, UpdatePlaybookMutationVariables>(UpdatePlaybookDocument, options);
      }
export type UpdatePlaybookMutationHookResult = ReturnType<typeof useUpdatePlaybookMutation>;
export type UpdatePlaybookMutationResult = Apollo.MutationResult<UpdatePlaybookMutation>;
export type UpdatePlaybookMutationOptions = Apollo.BaseMutationOptions<UpdatePlaybookMutation, UpdatePlaybookMutationVariables>;
export const SavePlaybookDocument = gql`
    mutation SavePlaybook($projectId: UUID!) {
  playbookSave(projectId: $projectId)
}
    `;
export type SavePlaybookMutationFn = Apollo.MutationFunction<SavePlaybookMutation, SavePlaybookMutationVariables>;

/**
 * __useSavePlaybookMutation__
 *
 * To run a mutation, you first call `useSavePlaybookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSavePlaybookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [savePlaybookMutation, { data, loading, error }] = useSavePlaybookMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useSavePlaybookMutation(baseOptions?: Apollo.MutationHookOptions<SavePlaybookMutation, SavePlaybookMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SavePlaybookMutation, SavePlaybookMutationVariables>(SavePlaybookDocument, options);
      }
export type SavePlaybookMutationHookResult = ReturnType<typeof useSavePlaybookMutation>;
export type SavePlaybookMutationResult = Apollo.MutationResult<SavePlaybookMutation>;
export type SavePlaybookMutationOptions = Apollo.BaseMutationOptions<SavePlaybookMutation, SavePlaybookMutationVariables>;
export const PlaybookFromYamlDocument = gql`
    mutation PlaybookFromYaml($projectId: UUID!, $yaml: String!) {
  playbookFromYaml(projectId: $projectId, yaml: $yaml)
}
    `;
export type PlaybookFromYamlMutationFn = Apollo.MutationFunction<PlaybookFromYamlMutation, PlaybookFromYamlMutationVariables>;

/**
 * __usePlaybookFromYamlMutation__
 *
 * To run a mutation, you first call `usePlaybookFromYamlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlaybookFromYamlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [playbookFromYamlMutation, { data, loading, error }] = usePlaybookFromYamlMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      yaml: // value for 'yaml'
 *   },
 * });
 */
export function usePlaybookFromYamlMutation(baseOptions?: Apollo.MutationHookOptions<PlaybookFromYamlMutation, PlaybookFromYamlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PlaybookFromYamlMutation, PlaybookFromYamlMutationVariables>(PlaybookFromYamlDocument, options);
      }
export type PlaybookFromYamlMutationHookResult = ReturnType<typeof usePlaybookFromYamlMutation>;
export type PlaybookFromYamlMutationResult = Apollo.MutationResult<PlaybookFromYamlMutation>;
export type PlaybookFromYamlMutationOptions = Apollo.BaseMutationOptions<PlaybookFromYamlMutation, PlaybookFromYamlMutationVariables>;
export const LoadProvisionerDocument = gql`
    query LoadProvisioner($project_id: UUID!, $id: UUID!) {
  project(id: $project_id) {
    id
    provisioner(id: $id) {
      id
      name
      station
      active
      token
      creationDate
      changeDate
      currentDevice {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useLoadProvisionerQuery__
 *
 * To run a query within a React component, call `useLoadProvisionerQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadProvisionerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadProvisionerQuery({
 *   variables: {
 *      project_id: // value for 'project_id'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLoadProvisionerQuery(baseOptions: Apollo.QueryHookOptions<LoadProvisionerQuery, LoadProvisionerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoadProvisionerQuery, LoadProvisionerQueryVariables>(LoadProvisionerDocument, options);
      }
export function useLoadProvisionerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoadProvisionerQuery, LoadProvisionerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoadProvisionerQuery, LoadProvisionerQueryVariables>(LoadProvisionerDocument, options);
        }
export function useLoadProvisionerSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LoadProvisionerQuery, LoadProvisionerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LoadProvisionerQuery, LoadProvisionerQueryVariables>(LoadProvisionerDocument, options);
        }
export type LoadProvisionerQueryHookResult = ReturnType<typeof useLoadProvisionerQuery>;
export type LoadProvisionerLazyQueryHookResult = ReturnType<typeof useLoadProvisionerLazyQuery>;
export type LoadProvisionerSuspenseQueryHookResult = ReturnType<typeof useLoadProvisionerSuspenseQuery>;
export type LoadProvisionerQueryResult = Apollo.QueryResult<LoadProvisionerQuery, LoadProvisionerQueryVariables>;
export const RegenerateProvisionerTokenDocument = gql`
    mutation RegenerateProvisionerToken($id: UUID!) {
  provisionerRegenerateToken(id: $id) {
    id
    name
    station
    active
    token
    creationDate
    changeDate
    currentDevice {
      id
      name
    }
  }
}
    `;
export type RegenerateProvisionerTokenMutationFn = Apollo.MutationFunction<RegenerateProvisionerTokenMutation, RegenerateProvisionerTokenMutationVariables>;

/**
 * __useRegenerateProvisionerTokenMutation__
 *
 * To run a mutation, you first call `useRegenerateProvisionerTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegenerateProvisionerTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [regenerateProvisionerTokenMutation, { data, loading, error }] = useRegenerateProvisionerTokenMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRegenerateProvisionerTokenMutation(baseOptions?: Apollo.MutationHookOptions<RegenerateProvisionerTokenMutation, RegenerateProvisionerTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegenerateProvisionerTokenMutation, RegenerateProvisionerTokenMutationVariables>(RegenerateProvisionerTokenDocument, options);
      }
export type RegenerateProvisionerTokenMutationHookResult = ReturnType<typeof useRegenerateProvisionerTokenMutation>;
export type RegenerateProvisionerTokenMutationResult = Apollo.MutationResult<RegenerateProvisionerTokenMutation>;
export type RegenerateProvisionerTokenMutationOptions = Apollo.BaseMutationOptions<RegenerateProvisionerTokenMutation, RegenerateProvisionerTokenMutationVariables>;
export const DeleteProvisionerDocument = gql`
    mutation DeleteProvisioner($id: UUID!) {
  provisionerDelete(id: $id)
}
    `;
export type DeleteProvisionerMutationFn = Apollo.MutationFunction<DeleteProvisionerMutation, DeleteProvisionerMutationVariables>;

/**
 * __useDeleteProvisionerMutation__
 *
 * To run a mutation, you first call `useDeleteProvisionerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProvisionerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProvisionerMutation, { data, loading, error }] = useDeleteProvisionerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProvisionerMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProvisionerMutation, DeleteProvisionerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProvisionerMutation, DeleteProvisionerMutationVariables>(DeleteProvisionerDocument, options);
      }
export type DeleteProvisionerMutationHookResult = ReturnType<typeof useDeleteProvisionerMutation>;
export type DeleteProvisionerMutationResult = Apollo.MutationResult<DeleteProvisionerMutation>;
export type DeleteProvisionerMutationOptions = Apollo.BaseMutationOptions<DeleteProvisionerMutation, DeleteProvisionerMutationVariables>;
export const RenameProvisionerDocument = gql`
    mutation RenameProvisioner($id: UUID!, $name: String!) {
  provisionerRename(id: $id, name: $name) {
    id
    name
    station
    active
    token
    creationDate
    changeDate
    currentDevice {
      id
      name
    }
  }
}
    `;
export type RenameProvisionerMutationFn = Apollo.MutationFunction<RenameProvisionerMutation, RenameProvisionerMutationVariables>;

/**
 * __useRenameProvisionerMutation__
 *
 * To run a mutation, you first call `useRenameProvisionerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameProvisionerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameProvisionerMutation, { data, loading, error }] = useRenameProvisionerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useRenameProvisionerMutation(baseOptions?: Apollo.MutationHookOptions<RenameProvisionerMutation, RenameProvisionerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RenameProvisionerMutation, RenameProvisionerMutationVariables>(RenameProvisionerDocument, options);
      }
export type RenameProvisionerMutationHookResult = ReturnType<typeof useRenameProvisionerMutation>;
export type RenameProvisionerMutationResult = Apollo.MutationResult<RenameProvisionerMutation>;
export type RenameProvisionerMutationOptions = Apollo.BaseMutationOptions<RenameProvisionerMutation, RenameProvisionerMutationVariables>;
export const AssignStationToProvisionerDocument = gql`
    mutation AssignStationToProvisioner($id: UUID!, $station: String!) {
  provisionerAssignStation(id: $id, station: $station) {
    id
    name
    station
    active
    token
    creationDate
    changeDate
    currentDevice {
      id
      name
    }
  }
}
    `;
export type AssignStationToProvisionerMutationFn = Apollo.MutationFunction<AssignStationToProvisionerMutation, AssignStationToProvisionerMutationVariables>;

/**
 * __useAssignStationToProvisionerMutation__
 *
 * To run a mutation, you first call `useAssignStationToProvisionerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignStationToProvisionerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignStationToProvisionerMutation, { data, loading, error }] = useAssignStationToProvisionerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      station: // value for 'station'
 *   },
 * });
 */
export function useAssignStationToProvisionerMutation(baseOptions?: Apollo.MutationHookOptions<AssignStationToProvisionerMutation, AssignStationToProvisionerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssignStationToProvisionerMutation, AssignStationToProvisionerMutationVariables>(AssignStationToProvisionerDocument, options);
      }
export type AssignStationToProvisionerMutationHookResult = ReturnType<typeof useAssignStationToProvisionerMutation>;
export type AssignStationToProvisionerMutationResult = Apollo.MutationResult<AssignStationToProvisionerMutation>;
export type AssignStationToProvisionerMutationOptions = Apollo.BaseMutationOptions<AssignStationToProvisionerMutation, AssignStationToProvisionerMutationVariables>;
export const CreateProvisionerDocument = gql`
    mutation CreateProvisioner($projectId: UUID!, $name: String!, $station: String!) {
  provisionerCreate(projectId: $projectId, name: $name, station: $station) {
    id
    name
    station
    active
    token
    creationDate
    changeDate
    currentDevice {
      id
    }
  }
}
    `;
export type CreateProvisionerMutationFn = Apollo.MutationFunction<CreateProvisionerMutation, CreateProvisionerMutationVariables>;

/**
 * __useCreateProvisionerMutation__
 *
 * To run a mutation, you first call `useCreateProvisionerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProvisionerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProvisionerMutation, { data, loading, error }] = useCreateProvisionerMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      name: // value for 'name'
 *      station: // value for 'station'
 *   },
 * });
 */
export function useCreateProvisionerMutation(baseOptions?: Apollo.MutationHookOptions<CreateProvisionerMutation, CreateProvisionerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProvisionerMutation, CreateProvisionerMutationVariables>(CreateProvisionerDocument, options);
      }
export type CreateProvisionerMutationHookResult = ReturnType<typeof useCreateProvisionerMutation>;
export type CreateProvisionerMutationResult = Apollo.MutationResult<CreateProvisionerMutation>;
export type CreateProvisionerMutationOptions = Apollo.BaseMutationOptions<CreateProvisionerMutation, CreateProvisionerMutationVariables>;
export const PlaybookDocument = gql`
    query Playbook($projectId: UUID!) {
  project(id: $projectId) {
    id
    playbook {
      version
      stations {
        name
      }
    }
  }
}
    `;

/**
 * __usePlaybookQuery__
 *
 * To run a query within a React component, call `usePlaybookQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlaybookQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlaybookQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function usePlaybookQuery(baseOptions: Apollo.QueryHookOptions<PlaybookQuery, PlaybookQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlaybookQuery, PlaybookQueryVariables>(PlaybookDocument, options);
      }
export function usePlaybookLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlaybookQuery, PlaybookQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlaybookQuery, PlaybookQueryVariables>(PlaybookDocument, options);
        }
export function usePlaybookSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PlaybookQuery, PlaybookQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PlaybookQuery, PlaybookQueryVariables>(PlaybookDocument, options);
        }
export type PlaybookQueryHookResult = ReturnType<typeof usePlaybookQuery>;
export type PlaybookLazyQueryHookResult = ReturnType<typeof usePlaybookLazyQuery>;
export type PlaybookSuspenseQueryHookResult = ReturnType<typeof usePlaybookSuspenseQuery>;
export type PlaybookQueryResult = Apollo.QueryResult<PlaybookQuery, PlaybookQueryVariables>;
export const ProvisionersDocument = gql`
    query Provisioners($projectId: UUID!) {
  project(id: $projectId) {
    id
    provisioners {
      id
      name
      token
      active
      currentDevice {
        id
      }
    }
  }
}
    `;

/**
 * __useProvisionersQuery__
 *
 * To run a query within a React component, call `useProvisionersQuery` and pass it any options that fit your needs.
 * When your component renders, `useProvisionersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProvisionersQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useProvisionersQuery(baseOptions: Apollo.QueryHookOptions<ProvisionersQuery, ProvisionersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProvisionersQuery, ProvisionersQueryVariables>(ProvisionersDocument, options);
      }
export function useProvisionersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProvisionersQuery, ProvisionersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProvisionersQuery, ProvisionersQueryVariables>(ProvisionersDocument, options);
        }
export function useProvisionersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ProvisionersQuery, ProvisionersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProvisionersQuery, ProvisionersQueryVariables>(ProvisionersDocument, options);
        }
export type ProvisionersQueryHookResult = ReturnType<typeof useProvisionersQuery>;
export type ProvisionersLazyQueryHookResult = ReturnType<typeof useProvisionersLazyQuery>;
export type ProvisionersSuspenseQueryHookResult = ReturnType<typeof useProvisionersSuspenseQuery>;
export type ProvisionersQueryResult = Apollo.QueryResult<ProvisionersQuery, ProvisionersQueryVariables>;
export const LoadRequirementDocument = gql`
    query LoadRequirement($projectId: UUID!, $requirementId: UUID!) {
  project(id: $projectId) {
    id
    requirement(id: $requirementId) {
      id
      name
      description
      active
      creationDate
      deletionDate
      schema {
        id
        name
      }
      upperLimit
      lowerLimit
      inclusiveLower
      inclusiveUpper
    }
  }
}
    `;

/**
 * __useLoadRequirementQuery__
 *
 * To run a query within a React component, call `useLoadRequirementQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadRequirementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadRequirementQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      requirementId: // value for 'requirementId'
 *   },
 * });
 */
export function useLoadRequirementQuery(baseOptions: Apollo.QueryHookOptions<LoadRequirementQuery, LoadRequirementQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoadRequirementQuery, LoadRequirementQueryVariables>(LoadRequirementDocument, options);
      }
export function useLoadRequirementLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoadRequirementQuery, LoadRequirementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoadRequirementQuery, LoadRequirementQueryVariables>(LoadRequirementDocument, options);
        }
export function useLoadRequirementSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LoadRequirementQuery, LoadRequirementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LoadRequirementQuery, LoadRequirementQueryVariables>(LoadRequirementDocument, options);
        }
export type LoadRequirementQueryHookResult = ReturnType<typeof useLoadRequirementQuery>;
export type LoadRequirementLazyQueryHookResult = ReturnType<typeof useLoadRequirementLazyQuery>;
export type LoadRequirementSuspenseQueryHookResult = ReturnType<typeof useLoadRequirementSuspenseQuery>;
export type LoadRequirementQueryResult = Apollo.QueryResult<LoadRequirementQuery, LoadRequirementQueryVariables>;
export const DeleteRequirementDocument = gql`
    mutation DeleteRequirement($requirementId: UUID!) {
  requirementDelete(id: $requirementId)
}
    `;
export type DeleteRequirementMutationFn = Apollo.MutationFunction<DeleteRequirementMutation, DeleteRequirementMutationVariables>;

/**
 * __useDeleteRequirementMutation__
 *
 * To run a mutation, you first call `useDeleteRequirementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRequirementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRequirementMutation, { data, loading, error }] = useDeleteRequirementMutation({
 *   variables: {
 *      requirementId: // value for 'requirementId'
 *   },
 * });
 */
export function useDeleteRequirementMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRequirementMutation, DeleteRequirementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRequirementMutation, DeleteRequirementMutationVariables>(DeleteRequirementDocument, options);
      }
export type DeleteRequirementMutationHookResult = ReturnType<typeof useDeleteRequirementMutation>;
export type DeleteRequirementMutationResult = Apollo.MutationResult<DeleteRequirementMutation>;
export type DeleteRequirementMutationOptions = Apollo.BaseMutationOptions<DeleteRequirementMutation, DeleteRequirementMutationVariables>;
export const CreateRequirementDocument = gql`
    mutation CreateRequirement($projectId: UUID!, $schemaId: UUID!, $name: String!, $description: String, $lowerLimit: Float, $inclusiveLower: Boolean!, $upperLimit: Float, $inclusiveUpper: Boolean!) {
  requirementCreate(
    projectId: $projectId
    schemaId: $schemaId
    description: $description
    name: $name
    lowerLimit: $lowerLimit
    inclusiveLower: $inclusiveLower
    upperLimit: $upperLimit
    inclusiveUpper: $inclusiveUpper
  ) {
    id
  }
}
    `;
export type CreateRequirementMutationFn = Apollo.MutationFunction<CreateRequirementMutation, CreateRequirementMutationVariables>;

/**
 * __useCreateRequirementMutation__
 *
 * To run a mutation, you first call `useCreateRequirementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRequirementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRequirementMutation, { data, loading, error }] = useCreateRequirementMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      schemaId: // value for 'schemaId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      lowerLimit: // value for 'lowerLimit'
 *      inclusiveLower: // value for 'inclusiveLower'
 *      upperLimit: // value for 'upperLimit'
 *      inclusiveUpper: // value for 'inclusiveUpper'
 *   },
 * });
 */
export function useCreateRequirementMutation(baseOptions?: Apollo.MutationHookOptions<CreateRequirementMutation, CreateRequirementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRequirementMutation, CreateRequirementMutationVariables>(CreateRequirementDocument, options);
      }
export type CreateRequirementMutationHookResult = ReturnType<typeof useCreateRequirementMutation>;
export type CreateRequirementMutationResult = Apollo.MutationResult<CreateRequirementMutation>;
export type CreateRequirementMutationOptions = Apollo.BaseMutationOptions<CreateRequirementMutation, CreateRequirementMutationVariables>;
export const RequirementsDocument = gql`
    query Requirements($projectId: UUID!) {
  project(id: $projectId) {
    id
    requirements {
      id
      name
      active
      description
      creationDate
    }
  }
}
    `;

/**
 * __useRequirementsQuery__
 *
 * To run a query within a React component, call `useRequirementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequirementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequirementsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useRequirementsQuery(baseOptions: Apollo.QueryHookOptions<RequirementsQuery, RequirementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RequirementsQuery, RequirementsQueryVariables>(RequirementsDocument, options);
      }
export function useRequirementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RequirementsQuery, RequirementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RequirementsQuery, RequirementsQueryVariables>(RequirementsDocument, options);
        }
export function useRequirementsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<RequirementsQuery, RequirementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RequirementsQuery, RequirementsQueryVariables>(RequirementsDocument, options);
        }
export type RequirementsQueryHookResult = ReturnType<typeof useRequirementsQuery>;
export type RequirementsLazyQueryHookResult = ReturnType<typeof useRequirementsLazyQuery>;
export type RequirementsSuspenseQueryHookResult = ReturnType<typeof useRequirementsSuspenseQuery>;
export type RequirementsQueryResult = Apollo.QueryResult<RequirementsQuery, RequirementsQueryVariables>;
export const MoveProjectDocument = gql`
    mutation MoveProject($projectId: UUID!, $newOwner: UUID!) {
  projectTransfer(id: $projectId, ownerId: $newOwner)
}
    `;
export type MoveProjectMutationFn = Apollo.MutationFunction<MoveProjectMutation, MoveProjectMutationVariables>;

/**
 * __useMoveProjectMutation__
 *
 * To run a mutation, you first call `useMoveProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveProjectMutation, { data, loading, error }] = useMoveProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      newOwner: // value for 'newOwner'
 *   },
 * });
 */
export function useMoveProjectMutation(baseOptions?: Apollo.MutationHookOptions<MoveProjectMutation, MoveProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MoveProjectMutation, MoveProjectMutationVariables>(MoveProjectDocument, options);
      }
export type MoveProjectMutationHookResult = ReturnType<typeof useMoveProjectMutation>;
export type MoveProjectMutationResult = Apollo.MutationResult<MoveProjectMutation>;
export type MoveProjectMutationOptions = Apollo.BaseMutationOptions<MoveProjectMutation, MoveProjectMutationVariables>;
export const DeleteProjectDocument = gql`
    mutation DeleteProject($projectId: UUID!) {
  projectDelete(id: $projectId)
}
    `;
export type DeleteProjectMutationFn = Apollo.MutationFunction<DeleteProjectMutation, DeleteProjectMutationVariables>;

/**
 * __useDeleteProjectMutation__
 *
 * To run a mutation, you first call `useDeleteProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectMutation, { data, loading, error }] = useDeleteProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useDeleteProjectMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProjectMutation, DeleteProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProjectMutation, DeleteProjectMutationVariables>(DeleteProjectDocument, options);
      }
export type DeleteProjectMutationHookResult = ReturnType<typeof useDeleteProjectMutation>;
export type DeleteProjectMutationResult = Apollo.MutationResult<DeleteProjectMutation>;
export type DeleteProjectMutationOptions = Apollo.BaseMutationOptions<DeleteProjectMutation, DeleteProjectMutationVariables>;
export const ProjectData2Document = gql`
    query ProjectData2($projectId: UUID!) {
  project(id: $projectId) {
    name
    creationDate
    deletionDate
    settings {
      integrations {
        matrix {
          rooms
        }
        telegram {
          chatIds
        }
      }
    }
    active
  }
}
    `;

/**
 * __useProjectData2Query__
 *
 * To run a query within a React component, call `useProjectData2Query` and pass it any options that fit your needs.
 * When your component renders, `useProjectData2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectData2Query({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useProjectData2Query(baseOptions: Apollo.QueryHookOptions<ProjectData2Query, ProjectData2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectData2Query, ProjectData2QueryVariables>(ProjectData2Document, options);
      }
export function useProjectData2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectData2Query, ProjectData2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectData2Query, ProjectData2QueryVariables>(ProjectData2Document, options);
        }
export function useProjectData2SuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ProjectData2Query, ProjectData2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProjectData2Query, ProjectData2QueryVariables>(ProjectData2Document, options);
        }
export type ProjectData2QueryHookResult = ReturnType<typeof useProjectData2Query>;
export type ProjectData2LazyQueryHookResult = ReturnType<typeof useProjectData2LazyQuery>;
export type ProjectData2SuspenseQueryHookResult = ReturnType<typeof useProjectData2SuspenseQuery>;
export type ProjectData2QueryResult = Apollo.QueryResult<ProjectData2Query, ProjectData2QueryVariables>;
export const UpdateProjectSettingsDocument = gql`
    mutation UpdateProjectSettings($projectId: UUID!, $settings: InputProjectSettings!) {
  projectSettingsUpdate(id: $projectId, settings: $settings)
}
    `;
export type UpdateProjectSettingsMutationFn = Apollo.MutationFunction<UpdateProjectSettingsMutation, UpdateProjectSettingsMutationVariables>;

/**
 * __useUpdateProjectSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateProjectSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectSettingsMutation, { data, loading, error }] = useUpdateProjectSettingsMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      settings: // value for 'settings'
 *   },
 * });
 */
export function useUpdateProjectSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProjectSettingsMutation, UpdateProjectSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProjectSettingsMutation, UpdateProjectSettingsMutationVariables>(UpdateProjectSettingsDocument, options);
      }
export type UpdateProjectSettingsMutationHookResult = ReturnType<typeof useUpdateProjectSettingsMutation>;
export type UpdateProjectSettingsMutationResult = Apollo.MutationResult<UpdateProjectSettingsMutation>;
export type UpdateProjectSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateProjectSettingsMutation, UpdateProjectSettingsMutationVariables>;
export const ProjectNameDocument = gql`
    query ProjectName($projectId: UUID!) {
  project(id: $projectId) {
    name
    owner {
      __typename
      ... on User {
        email
        id
      }
      ... on Group {
        name
        id
      }
    }
    creationDate
    deletionDate
    active
  }
}
    `;

/**
 * __useProjectNameQuery__
 *
 * To run a query within a React component, call `useProjectNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectNameQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useProjectNameQuery(baseOptions: Apollo.QueryHookOptions<ProjectNameQuery, ProjectNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectNameQuery, ProjectNameQueryVariables>(ProjectNameDocument, options);
      }
export function useProjectNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectNameQuery, ProjectNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectNameQuery, ProjectNameQueryVariables>(ProjectNameDocument, options);
        }
export function useProjectNameSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ProjectNameQuery, ProjectNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProjectNameQuery, ProjectNameQueryVariables>(ProjectNameDocument, options);
        }
export type ProjectNameQueryHookResult = ReturnType<typeof useProjectNameQuery>;
export type ProjectNameLazyQueryHookResult = ReturnType<typeof useProjectNameLazyQuery>;
export type ProjectNameSuspenseQueryHookResult = ReturnType<typeof useProjectNameSuspenseQuery>;
export type ProjectNameQueryResult = Apollo.QueryResult<ProjectNameQuery, ProjectNameQueryVariables>;
export const CurrentUserDocument = gql`
    query currentUser {
  currentUser {
    id
    admin
    email
  }
}
    `;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
      }
export function useCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
        }
export function useCurrentUserSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
        }
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserSuspenseQueryHookResult = ReturnType<typeof useCurrentUserSuspenseQuery>;
export type CurrentUserQueryResult = Apollo.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>;
export const RefreshDocument = gql`
    mutation refresh($token: String!, $impersonate: UUID) {
  refresh(token: $token, impersonate: $impersonate)
}
    `;
export type RefreshMutationFn = Apollo.MutationFunction<RefreshMutation, RefreshMutationVariables>;

/**
 * __useRefreshMutation__
 *
 * To run a mutation, you first call `useRefreshMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshMutation, { data, loading, error }] = useRefreshMutation({
 *   variables: {
 *      token: // value for 'token'
 *      impersonate: // value for 'impersonate'
 *   },
 * });
 */
export function useRefreshMutation(baseOptions?: Apollo.MutationHookOptions<RefreshMutation, RefreshMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshMutation, RefreshMutationVariables>(RefreshDocument, options);
      }
export type RefreshMutationHookResult = ReturnType<typeof useRefreshMutation>;
export type RefreshMutationResult = Apollo.MutationResult<RefreshMutation>;
export type RefreshMutationOptions = Apollo.BaseMutationOptions<RefreshMutation, RefreshMutationVariables>;
export const PingDocument = gql`
    query ping {
  ping
}
    `;

/**
 * __usePingQuery__
 *
 * To run a query within a React component, call `usePingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePingQuery({
 *   variables: {
 *   },
 * });
 */
export function usePingQuery(baseOptions?: Apollo.QueryHookOptions<PingQuery, PingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PingQuery, PingQueryVariables>(PingDocument, options);
      }
export function usePingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PingQuery, PingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PingQuery, PingQueryVariables>(PingDocument, options);
        }
export function usePingSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PingQuery, PingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PingQuery, PingQueryVariables>(PingDocument, options);
        }
export type PingQueryHookResult = ReturnType<typeof usePingQuery>;
export type PingLazyQueryHookResult = ReturnType<typeof usePingLazyQuery>;
export type PingSuspenseQueryHookResult = ReturnType<typeof usePingSuspenseQuery>;
export type PingQueryResult = Apollo.QueryResult<PingQuery, PingQueryVariables>;
export const ConfigDocument = gql`
    query config {
  config {
    name
    apiUrl
    frontendUrl
  }
}
    `;

/**
 * __useConfigQuery__
 *
 * To run a query within a React component, call `useConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useConfigQuery(baseOptions?: Apollo.QueryHookOptions<ConfigQuery, ConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConfigQuery, ConfigQueryVariables>(ConfigDocument, options);
      }
export function useConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConfigQuery, ConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConfigQuery, ConfigQueryVariables>(ConfigDocument, options);
        }
export function useConfigSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ConfigQuery, ConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ConfigQuery, ConfigQueryVariables>(ConfigDocument, options);
        }
export type ConfigQueryHookResult = ReturnType<typeof useConfigQuery>;
export type ConfigLazyQueryHookResult = ReturnType<typeof useConfigLazyQuery>;
export type ConfigSuspenseQueryHookResult = ReturnType<typeof useConfigSuspenseQuery>;
export type ConfigQueryResult = Apollo.QueryResult<ConfigQuery, ConfigQueryVariables>;
export type AppConfigKeySpecifier = ('apiUrl' | 'frontendUrl' | 'name' | AppConfigKeySpecifier)[];
export type AppConfigFieldPolicy = {
	apiUrl?: FieldPolicy<any> | FieldReadFunction<any>,
	frontendUrl?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ArtifactKeySpecifier = ('name' | 'signature' | 'url' | ArtifactKeySpecifier)[];
export type ArtifactFieldPolicy = {
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	signature?: FieldPolicy<any> | FieldReadFunction<any>,
	url?: FieldPolicy<any> | FieldReadFunction<any>
};
export type AttachmentKeySpecifier = ('data' | 'id' | 'mimeType' | 'name' | 'run' | 'runId' | AttachmentKeySpecifier)[];
export type AttachmentFieldPolicy = {
	data?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	mimeType?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	run?: FieldPolicy<any> | FieldReadFunction<any>,
	runId?: FieldPolicy<any> | FieldReadFunction<any>
};
export type BinaryKeySpecifier = ('active' | 'analysis' | 'chip' | 'chipId' | 'creationDate' | 'deletionDate' | 'id' | 'parts' | 'version' | 'versionMajor' | 'versionMinor' | 'versionPatch' | BinaryKeySpecifier)[];
export type BinaryFieldPolicy = {
	active?: FieldPolicy<any> | FieldReadFunction<any>,
	analysis?: FieldPolicy<any> | FieldReadFunction<any>,
	chip?: FieldPolicy<any> | FieldReadFunction<any>,
	chipId?: FieldPolicy<any> | FieldReadFunction<any>,
	creationDate?: FieldPolicy<any> | FieldReadFunction<any>,
	deletionDate?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	parts?: FieldPolicy<any> | FieldReadFunction<any>,
	version?: FieldPolicy<any> | FieldReadFunction<any>,
	versionMajor?: FieldPolicy<any> | FieldReadFunction<any>,
	versionMinor?: FieldPolicy<any> | FieldReadFunction<any>,
	versionPatch?: FieldPolicy<any> | FieldReadFunction<any>
};
export type BinaryAnalysisKeySpecifier = ('nvmSize' | BinaryAnalysisKeySpecifier)[];
export type BinaryAnalysisFieldPolicy = {
	nvmSize?: FieldPolicy<any> | FieldReadFunction<any>
};
export type BinaryPartKeySpecifier = ('analysis' | 'binaryId' | 'changeDate' | 'creationDate' | 'id' | 'imageHash' | 'kind' | 'memoryOffset' | 'name' | BinaryPartKeySpecifier)[];
export type BinaryPartFieldPolicy = {
	analysis?: FieldPolicy<any> | FieldReadFunction<any>,
	binaryId?: FieldPolicy<any> | FieldReadFunction<any>,
	changeDate?: FieldPolicy<any> | FieldReadFunction<any>,
	creationDate?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	imageHash?: FieldPolicy<any> | FieldReadFunction<any>,
	kind?: FieldPolicy<any> | FieldReadFunction<any>,
	memoryOffset?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>
};
export type BinaryPartAnalysisKeySpecifier = ('nvmSize' | 'rtt' | BinaryPartAnalysisKeySpecifier)[];
export type BinaryPartAnalysisFieldPolicy = {
	nvmSize?: FieldPolicy<any> | FieldReadFunction<any>,
	rtt?: FieldPolicy<any> | FieldReadFunction<any>
};
export type BlockKeySpecifier = ('byteSlice' | 'dataDecoded' | 'id' | 'run' | 'schema' | BlockKeySpecifier)[];
export type BlockFieldPolicy = {
	byteSlice?: FieldPolicy<any> | FieldReadFunction<any>,
	dataDecoded?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	run?: FieldPolicy<any> | FieldReadFunction<any>,
	schema?: FieldPolicy<any> | FieldReadFunction<any>
};
export type BlockSchemaKeySpecifier = ('active' | 'creationDate' | 'deletionDate' | 'displayName' | 'generator' | 'id' | 'isDeviceIdentifier' | 'memorySink' | 'memorySinks' | 'memorySource' | 'name' | 'projectId' | 'requirements' | 'schema' | 'supportsRequirements' | BlockSchemaKeySpecifier)[];
export type BlockSchemaFieldPolicy = {
	active?: FieldPolicy<any> | FieldReadFunction<any>,
	creationDate?: FieldPolicy<any> | FieldReadFunction<any>,
	deletionDate?: FieldPolicy<any> | FieldReadFunction<any>,
	displayName?: FieldPolicy<any> | FieldReadFunction<any>,
	generator?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	isDeviceIdentifier?: FieldPolicy<any> | FieldReadFunction<any>,
	memorySink?: FieldPolicy<any> | FieldReadFunction<any>,
	memorySinks?: FieldPolicy<any> | FieldReadFunction<any>,
	memorySource?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	projectId?: FieldPolicy<any> | FieldReadFunction<any>,
	requirements?: FieldPolicy<any> | FieldReadFunction<any>,
	schema?: FieldPolicy<any> | FieldReadFunction<any>,
	supportsRequirements?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ChipKeySpecifier = ('active' | 'binaries' | 'binary' | 'binaryNewest' | 'changeDate' | 'creationDate' | 'deletionDate' | 'id' | 'isSupported' | 'name' | 'nvmSize' | 'partNumber' | 'projectId' | ChipKeySpecifier)[];
export type ChipFieldPolicy = {
	active?: FieldPolicy<any> | FieldReadFunction<any>,
	binaries?: FieldPolicy<any> | FieldReadFunction<any>,
	binary?: FieldPolicy<any> | FieldReadFunction<any>,
	binaryNewest?: FieldPolicy<any> | FieldReadFunction<any>,
	changeDate?: FieldPolicy<any> | FieldReadFunction<any>,
	creationDate?: FieldPolicy<any> | FieldReadFunction<any>,
	deletionDate?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	isSupported?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	nvmSize?: FieldPolicy<any> | FieldReadFunction<any>,
	partNumber?: FieldPolicy<any> | FieldReadFunction<any>,
	projectId?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DataBlockKeySpecifier = ('blockSchema' | DataBlockKeySpecifier)[];
export type DataBlockFieldPolicy = {
	blockSchema?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DeviceKeySpecifier = ('active' | 'changeDate' | 'creationDate' | 'deletionDate' | 'id' | 'name' | 'project' | 'projectId' | 'run' | 'runs' | DeviceKeySpecifier)[];
export type DeviceFieldPolicy = {
	active?: FieldPolicy<any> | FieldReadFunction<any>,
	changeDate?: FieldPolicy<any> | FieldReadFunction<any>,
	creationDate?: FieldPolicy<any> | FieldReadFunction<any>,
	deletionDate?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	project?: FieldPolicy<any> | FieldReadFunction<any>,
	projectId?: FieldPolicy<any> | FieldReadFunction<any>,
	run?: FieldPolicy<any> | FieldReadFunction<any>,
	runs?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DeviceConnectionKeySpecifier = ('edges' | 'nodes' | 'numDevices' | 'pageInfo' | DeviceConnectionKeySpecifier)[];
export type DeviceConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	nodes?: FieldPolicy<any> | FieldReadFunction<any>,
	numDevices?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DeviceEdgeKeySpecifier = ('cursor' | 'node' | DeviceEdgeKeySpecifier)[];
export type DeviceEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type FamilyKeySpecifier = ('definition' | 'id' | 'owner' | FamilyKeySpecifier)[];
export type FamilyFieldPolicy = {
	definition?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	owner?: FieldPolicy<any> | FieldReadFunction<any>
};
export type FlashChipKeySpecifier = ('chip' | 'chipId' | 'command' | FlashChipKeySpecifier)[];
export type FlashChipFieldPolicy = {
	chip?: FieldPolicy<any> | FieldReadFunction<any>,
	chipId?: FieldPolicy<any> | FieldReadFunction<any>,
	command?: FieldPolicy<any> | FieldReadFunction<any>
};
export type GroupKeySpecifier = ('changeDate' | 'creationDate' | 'id' | 'name' | 'projects' | 'users' | GroupKeySpecifier)[];
export type GroupFieldPolicy = {
	changeDate?: FieldPolicy<any> | FieldReadFunction<any>,
	creationDate?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	projects?: FieldPolicy<any> | FieldReadFunction<any>,
	users?: FieldPolicy<any> | FieldReadFunction<any>
};
export type IntegrationsSettingsKeySpecifier = ('matrix' | 'telegram' | IntegrationsSettingsKeySpecifier)[];
export type IntegrationsSettingsFieldPolicy = {
	matrix?: FieldPolicy<any> | FieldReadFunction<any>,
	telegram?: FieldPolicy<any> | FieldReadFunction<any>
};
export type LogKeySpecifier = ('creationDate' | 'id' | 'level' | 'message' | 'run' | 'runId' | LogKeySpecifier)[];
export type LogFieldPolicy = {
	creationDate?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	level?: FieldPolicy<any> | FieldReadFunction<any>,
	message?: FieldPolicy<any> | FieldReadFunction<any>,
	run?: FieldPolicy<any> | FieldReadFunction<any>,
	runId?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MatrixSettingsKeySpecifier = ('rooms' | MatrixSettingsKeySpecifier)[];
export type MatrixSettingsFieldPolicy = {
	rooms?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MemoryEndpointKeySpecifier = ('active' | 'address' | 'chip' | 'creationDate' | 'deletionDate' | 'id' | 'length' | 'schema' | 'sink' | MemoryEndpointKeySpecifier)[];
export type MemoryEndpointFieldPolicy = {
	active?: FieldPolicy<any> | FieldReadFunction<any>,
	address?: FieldPolicy<any> | FieldReadFunction<any>,
	chip?: FieldPolicy<any> | FieldReadFunction<any>,
	creationDate?: FieldPolicy<any> | FieldReadFunction<any>,
	deletionDate?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	length?: FieldPolicy<any> | FieldReadFunction<any>,
	schema?: FieldPolicy<any> | FieldReadFunction<any>,
	sink?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MutationRootKeySpecifier = ('attachmentCreate' | 'binaryCreate' | 'binaryDelete' | 'blockCreate' | 'blockCreateFromBytes' | 'blockSchemaCreate' | 'blockSchemaDelete' | 'blockSchemaRename' | 'blockSchemaSetIdentifier' | 'changePassword' | 'chipCreate' | 'chipDelete' | 'deviceCreate' | 'deviceDelete' | 'familyCreate' | 'finishStep' | 'groupAddUser' | 'groupCreate' | 'groupRemoveUser' | 'logCreate' | 'paymentMethodCreate' | 'playbookFromYaml' | 'playbookSave' | 'playbookUpdate' | 'projectCreate' | 'projectDelete' | 'projectSettingsUpdate' | 'projectTransfer' | 'provisionerAssignStation' | 'provisionerCreate' | 'provisionerDelete' | 'provisionerRegenerateToken' | 'provisionerRename' | 'refresh' | 'requirementCreate' | 'requirementDelete' | 'runCreate' | 'runCreateFor' | 'runFinish' | 'signIn' | 'signInWithOauth' | 'startStep' | 'subscriptionUpdate' | 'userApprove' | 'userConfirm' | 'userCreate' | 'userRequestPasswordReset' | 'userResendConfirmCode' | 'userResetPassword' | MutationRootKeySpecifier)[];
export type MutationRootFieldPolicy = {
	attachmentCreate?: FieldPolicy<any> | FieldReadFunction<any>,
	binaryCreate?: FieldPolicy<any> | FieldReadFunction<any>,
	binaryDelete?: FieldPolicy<any> | FieldReadFunction<any>,
	blockCreate?: FieldPolicy<any> | FieldReadFunction<any>,
	blockCreateFromBytes?: FieldPolicy<any> | FieldReadFunction<any>,
	blockSchemaCreate?: FieldPolicy<any> | FieldReadFunction<any>,
	blockSchemaDelete?: FieldPolicy<any> | FieldReadFunction<any>,
	blockSchemaRename?: FieldPolicy<any> | FieldReadFunction<any>,
	blockSchemaSetIdentifier?: FieldPolicy<any> | FieldReadFunction<any>,
	changePassword?: FieldPolicy<any> | FieldReadFunction<any>,
	chipCreate?: FieldPolicy<any> | FieldReadFunction<any>,
	chipDelete?: FieldPolicy<any> | FieldReadFunction<any>,
	deviceCreate?: FieldPolicy<any> | FieldReadFunction<any>,
	deviceDelete?: FieldPolicy<any> | FieldReadFunction<any>,
	familyCreate?: FieldPolicy<any> | FieldReadFunction<any>,
	finishStep?: FieldPolicy<any> | FieldReadFunction<any>,
	groupAddUser?: FieldPolicy<any> | FieldReadFunction<any>,
	groupCreate?: FieldPolicy<any> | FieldReadFunction<any>,
	groupRemoveUser?: FieldPolicy<any> | FieldReadFunction<any>,
	logCreate?: FieldPolicy<any> | FieldReadFunction<any>,
	paymentMethodCreate?: FieldPolicy<any> | FieldReadFunction<any>,
	playbookFromYaml?: FieldPolicy<any> | FieldReadFunction<any>,
	playbookSave?: FieldPolicy<any> | FieldReadFunction<any>,
	playbookUpdate?: FieldPolicy<any> | FieldReadFunction<any>,
	projectCreate?: FieldPolicy<any> | FieldReadFunction<any>,
	projectDelete?: FieldPolicy<any> | FieldReadFunction<any>,
	projectSettingsUpdate?: FieldPolicy<any> | FieldReadFunction<any>,
	projectTransfer?: FieldPolicy<any> | FieldReadFunction<any>,
	provisionerAssignStation?: FieldPolicy<any> | FieldReadFunction<any>,
	provisionerCreate?: FieldPolicy<any> | FieldReadFunction<any>,
	provisionerDelete?: FieldPolicy<any> | FieldReadFunction<any>,
	provisionerRegenerateToken?: FieldPolicy<any> | FieldReadFunction<any>,
	provisionerRename?: FieldPolicy<any> | FieldReadFunction<any>,
	refresh?: FieldPolicy<any> | FieldReadFunction<any>,
	requirementCreate?: FieldPolicy<any> | FieldReadFunction<any>,
	requirementDelete?: FieldPolicy<any> | FieldReadFunction<any>,
	runCreate?: FieldPolicy<any> | FieldReadFunction<any>,
	runCreateFor?: FieldPolicy<any> | FieldReadFunction<any>,
	runFinish?: FieldPolicy<any> | FieldReadFunction<any>,
	signIn?: FieldPolicy<any> | FieldReadFunction<any>,
	signInWithOauth?: FieldPolicy<any> | FieldReadFunction<any>,
	startStep?: FieldPolicy<any> | FieldReadFunction<any>,
	subscriptionUpdate?: FieldPolicy<any> | FieldReadFunction<any>,
	userApprove?: FieldPolicy<any> | FieldReadFunction<any>,
	userConfirm?: FieldPolicy<any> | FieldReadFunction<any>,
	userCreate?: FieldPolicy<any> | FieldReadFunction<any>,
	userRequestPasswordReset?: FieldPolicy<any> | FieldReadFunction<any>,
	userResendConfirmCode?: FieldPolicy<any> | FieldReadFunction<any>,
	userResetPassword?: FieldPolicy<any> | FieldReadFunction<any>
};
export type NotificationKeySpecifier = ('creationDate' | 'deliveryDate' | 'event' | 'id' | 'project' | 'projectId' | 'retry' | NotificationKeySpecifier)[];
export type NotificationFieldPolicy = {
	creationDate?: FieldPolicy<any> | FieldReadFunction<any>,
	deliveryDate?: FieldPolicy<any> | FieldReadFunction<any>,
	event?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	project?: FieldPolicy<any> | FieldReadFunction<any>,
	projectId?: FieldPolicy<any> | FieldReadFunction<any>,
	retry?: FieldPolicy<any> | FieldReadFunction<any>
};
export type NotificationConnectionKeySpecifier = ('edges' | 'nodes' | 'numNotifications' | 'pageInfo' | NotificationConnectionKeySpecifier)[];
export type NotificationConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	nodes?: FieldPolicy<any> | FieldReadFunction<any>,
	numNotifications?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
};
export type NotificationEdgeKeySpecifier = ('cursor' | 'node' | NotificationEdgeKeySpecifier)[];
export type NotificationEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type OauthDataKeySpecifier = ('authUrl' | 'csrfToken' | 'nonce' | OauthDataKeySpecifier)[];
export type OauthDataFieldPolicy = {
	authUrl?: FieldPolicy<any> | FieldReadFunction<any>,
	csrfToken?: FieldPolicy<any> | FieldReadFunction<any>,
	nonce?: FieldPolicy<any> | FieldReadFunction<any>
};
export type OauthUserKeySpecifier = ('provider' | 'subject' | OauthUserKeySpecifier)[];
export type OauthUserFieldPolicy = {
	provider?: FieldPolicy<any> | FieldReadFunction<any>,
	subject?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageInfoKeySpecifier = ('endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | PageInfoKeySpecifier)[];
export type PageInfoFieldPolicy = {
	endCursor?: FieldPolicy<any> | FieldReadFunction<any>,
	hasNextPage?: FieldPolicy<any> | FieldReadFunction<any>,
	hasPreviousPage?: FieldPolicy<any> | FieldReadFunction<any>,
	startCursor?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PaymentMethodKeySpecifier = ('id' | PaymentMethodKeySpecifier)[];
export type PaymentMethodFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PlaybookKeySpecifier = ('project' | 'projectId' | 'station' | 'stations' | 'version' | PlaybookKeySpecifier)[];
export type PlaybookFieldPolicy = {
	project?: FieldPolicy<any> | FieldReadFunction<any>,
	projectId?: FieldPolicy<any> | FieldReadFunction<any>,
	station?: FieldPolicy<any> | FieldReadFunction<any>,
	stations?: FieldPolicy<any> | FieldReadFunction<any>,
	version?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProjectKeySpecifier = ('active' | 'blockSchema' | 'blockSchemas' | 'changeDate' | 'chip' | 'chips' | 'creationDate' | 'deletionDate' | 'device' | 'devices' | 'id' | 'name' | 'notifications' | 'owner' | 'playbook' | 'playbooks' | 'provisioner' | 'provisioners' | 'requirement' | 'requirements' | 'settings' | ProjectKeySpecifier)[];
export type ProjectFieldPolicy = {
	active?: FieldPolicy<any> | FieldReadFunction<any>,
	blockSchema?: FieldPolicy<any> | FieldReadFunction<any>,
	blockSchemas?: FieldPolicy<any> | FieldReadFunction<any>,
	changeDate?: FieldPolicy<any> | FieldReadFunction<any>,
	chip?: FieldPolicy<any> | FieldReadFunction<any>,
	chips?: FieldPolicy<any> | FieldReadFunction<any>,
	creationDate?: FieldPolicy<any> | FieldReadFunction<any>,
	deletionDate?: FieldPolicy<any> | FieldReadFunction<any>,
	device?: FieldPolicy<any> | FieldReadFunction<any>,
	devices?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	notifications?: FieldPolicy<any> | FieldReadFunction<any>,
	owner?: FieldPolicy<any> | FieldReadFunction<any>,
	playbook?: FieldPolicy<any> | FieldReadFunction<any>,
	playbooks?: FieldPolicy<any> | FieldReadFunction<any>,
	provisioner?: FieldPolicy<any> | FieldReadFunction<any>,
	provisioners?: FieldPolicy<any> | FieldReadFunction<any>,
	requirement?: FieldPolicy<any> | FieldReadFunction<any>,
	requirements?: FieldPolicy<any> | FieldReadFunction<any>,
	settings?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProjectSettingsKeySpecifier = ('integrations' | ProjectSettingsKeySpecifier)[];
export type ProjectSettingsFieldPolicy = {
	integrations?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProvisionerKeySpecifier = ('active' | 'changeDate' | 'creationDate' | 'currentDevice' | 'currentDeviceId' | 'currentRun' | 'id' | 'name' | 'project' | 'projectId' | 'station' | 'steps' | 'token' | ProvisionerKeySpecifier)[];
export type ProvisionerFieldPolicy = {
	active?: FieldPolicy<any> | FieldReadFunction<any>,
	changeDate?: FieldPolicy<any> | FieldReadFunction<any>,
	creationDate?: FieldPolicy<any> | FieldReadFunction<any>,
	currentDevice?: FieldPolicy<any> | FieldReadFunction<any>,
	currentDeviceId?: FieldPolicy<any> | FieldReadFunction<any>,
	currentRun?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	project?: FieldPolicy<any> | FieldReadFunction<any>,
	projectId?: FieldPolicy<any> | FieldReadFunction<any>,
	station?: FieldPolicy<any> | FieldReadFunction<any>,
	steps?: FieldPolicy<any> | FieldReadFunction<any>,
	token?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProvisionerReleasesKeySpecifier = ('ui' | ProvisionerReleasesKeySpecifier)[];
export type ProvisionerReleasesFieldPolicy = {
	ui?: FieldPolicy<any> | FieldReadFunction<any>
};
export type QueryRootKeySpecifier = ('availableTargets' | 'config' | 'currentProvisioner' | 'currentUser' | 'family' | 'group' | 'oauthUrl' | 'paymentMethod' | 'paymentMethods' | 'ping' | 'project' | 'projects' | 'provisionerReleases' | 'stripeData' | 'subscription' | 'users' | 'usersPendingApproval' | QueryRootKeySpecifier)[];
export type QueryRootFieldPolicy = {
	availableTargets?: FieldPolicy<any> | FieldReadFunction<any>,
	config?: FieldPolicy<any> | FieldReadFunction<any>,
	currentProvisioner?: FieldPolicy<any> | FieldReadFunction<any>,
	currentUser?: FieldPolicy<any> | FieldReadFunction<any>,
	family?: FieldPolicy<any> | FieldReadFunction<any>,
	group?: FieldPolicy<any> | FieldReadFunction<any>,
	oauthUrl?: FieldPolicy<any> | FieldReadFunction<any>,
	paymentMethod?: FieldPolicy<any> | FieldReadFunction<any>,
	paymentMethods?: FieldPolicy<any> | FieldReadFunction<any>,
	ping?: FieldPolicy<any> | FieldReadFunction<any>,
	project?: FieldPolicy<any> | FieldReadFunction<any>,
	projects?: FieldPolicy<any> | FieldReadFunction<any>,
	provisionerReleases?: FieldPolicy<any> | FieldReadFunction<any>,
	stripeData?: FieldPolicy<any> | FieldReadFunction<any>,
	subscription?: FieldPolicy<any> | FieldReadFunction<any>,
	users?: FieldPolicy<any> | FieldReadFunction<any>,
	usersPendingApproval?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ReleaseKeySpecifier = ('artifacts' | 'name' | 'releaseNotes' | 'version' | ReleaseKeySpecifier)[];
export type ReleaseFieldPolicy = {
	artifacts?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	releaseNotes?: FieldPolicy<any> | FieldReadFunction<any>,
	version?: FieldPolicy<any> | FieldReadFunction<any>
};
export type RequirementKeySpecifier = ('active' | 'creationDate' | 'deletionDate' | 'description' | 'id' | 'inclusiveLower' | 'inclusiveUpper' | 'lowerLimit' | 'name' | 'schema' | 'schemaId' | 'upperLimit' | RequirementKeySpecifier)[];
export type RequirementFieldPolicy = {
	active?: FieldPolicy<any> | FieldReadFunction<any>,
	creationDate?: FieldPolicy<any> | FieldReadFunction<any>,
	deletionDate?: FieldPolicy<any> | FieldReadFunction<any>,
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	inclusiveLower?: FieldPolicy<any> | FieldReadFunction<any>,
	inclusiveUpper?: FieldPolicy<any> | FieldReadFunction<any>,
	lowerLimit?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	schema?: FieldPolicy<any> | FieldReadFunction<any>,
	schemaId?: FieldPolicy<any> | FieldReadFunction<any>,
	upperLimit?: FieldPolicy<any> | FieldReadFunction<any>
};
export type RequirementCheckKeySpecifier = ('block' | 'blockId' | 'checkDate' | 'id' | 'passed' | 'requirement' | 'requirementId' | 'runId' | RequirementCheckKeySpecifier)[];
export type RequirementCheckFieldPolicy = {
	block?: FieldPolicy<any> | FieldReadFunction<any>,
	blockId?: FieldPolicy<any> | FieldReadFunction<any>,
	checkDate?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	passed?: FieldPolicy<any> | FieldReadFunction<any>,
	requirement?: FieldPolicy<any> | FieldReadFunction<any>,
	requirementId?: FieldPolicy<any> | FieldReadFunction<any>,
	runId?: FieldPolicy<any> | FieldReadFunction<any>
};
export type RttAnalysisKeySpecifier = ('headerLocation' | RttAnalysisKeySpecifier)[];
export type RttAnalysisFieldPolicy = {
	headerLocation?: FieldPolicy<any> | FieldReadFunction<any>
};
export type RunKeySpecifier = ('attachments' | 'block' | 'blockForSchema' | 'blockSchemas' | 'blocks' | 'changeDate' | 'creationDate' | 'device' | 'deviceId' | 'errors' | 'finished' | 'id' | 'logs' | 'projectId' | 'provisioner' | 'requirementChecks' | 'requirements' | 'runStatus' | 'station' | 'steps' | RunKeySpecifier)[];
export type RunFieldPolicy = {
	attachments?: FieldPolicy<any> | FieldReadFunction<any>,
	block?: FieldPolicy<any> | FieldReadFunction<any>,
	blockForSchema?: FieldPolicy<any> | FieldReadFunction<any>,
	blockSchemas?: FieldPolicy<any> | FieldReadFunction<any>,
	blocks?: FieldPolicy<any> | FieldReadFunction<any>,
	changeDate?: FieldPolicy<any> | FieldReadFunction<any>,
	creationDate?: FieldPolicy<any> | FieldReadFunction<any>,
	device?: FieldPolicy<any> | FieldReadFunction<any>,
	deviceId?: FieldPolicy<any> | FieldReadFunction<any>,
	errors?: FieldPolicy<any> | FieldReadFunction<any>,
	finished?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	logs?: FieldPolicy<any> | FieldReadFunction<any>,
	projectId?: FieldPolicy<any> | FieldReadFunction<any>,
	provisioner?: FieldPolicy<any> | FieldReadFunction<any>,
	requirementChecks?: FieldPolicy<any> | FieldReadFunction<any>,
	requirements?: FieldPolicy<any> | FieldReadFunction<any>,
	runStatus?: FieldPolicy<any> | FieldReadFunction<any>,
	station?: FieldPolicy<any> | FieldReadFunction<any>,
	steps?: FieldPolicy<any> | FieldReadFunction<any>
};
export type RunCommandKeySpecifier = ('command' | 'directory' | RunCommandKeySpecifier)[];
export type RunCommandFieldPolicy = {
	command?: FieldPolicy<any> | FieldReadFunction<any>,
	directory?: FieldPolicy<any> | FieldReadFunction<any>
};
export type RunStepKeySpecifier = ('changeDate' | 'creationDate' | 'data' | 'id' | 'runId' | 'station' | 'status' | 'step' | 'stepId' | RunStepKeySpecifier)[];
export type RunStepFieldPolicy = {
	changeDate?: FieldPolicy<any> | FieldReadFunction<any>,
	creationDate?: FieldPolicy<any> | FieldReadFunction<any>,
	data?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	runId?: FieldPolicy<any> | FieldReadFunction<any>,
	station?: FieldPolicy<any> | FieldReadFunction<any>,
	status?: FieldPolicy<any> | FieldReadFunction<any>,
	step?: FieldPolicy<any> | FieldReadFunction<any>,
	stepId?: FieldPolicy<any> | FieldReadFunction<any>
};
export type RunStepDataKeySpecifier = ('binary' | 'binaryId' | RunStepDataKeySpecifier)[];
export type RunStepDataFieldPolicy = {
	binary?: FieldPolicy<any> | FieldReadFunction<any>,
	binaryId?: FieldPolicy<any> | FieldReadFunction<any>
};
export type StationKeySpecifier = ('color' | 'name' | 'steps' | StationKeySpecifier)[];
export type StationFieldPolicy = {
	color?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	steps?: FieldPolicy<any> | FieldReadFunction<any>
};
export type StepKeySpecifier = ('changeDate' | 'creationDate' | 'id' | 'kind' | 'label' | 'position' | 'station' | StepKeySpecifier)[];
export type StepFieldPolicy = {
	changeDate?: FieldPolicy<any> | FieldReadFunction<any>,
	creationDate?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	kind?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>,
	position?: FieldPolicy<any> | FieldReadFunction<any>,
	station?: FieldPolicy<any> | FieldReadFunction<any>
};
export type StripeDataKeySpecifier = ('publicKey' | StripeDataKeySpecifier)[];
export type StripeDataFieldPolicy = {
	publicKey?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SubscriptionKeySpecifier = ('id' | 'user' | SubscriptionKeySpecifier)[];
export type SubscriptionFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	user?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SubscriptionRootKeySpecifier = ('createBlockEvent' | 'createBlockSchemaEvent' | 'createDeviceEvent' | 'createRequirementEvent' | 'createRunEvent' | 'logEvent' | SubscriptionRootKeySpecifier)[];
export type SubscriptionRootFieldPolicy = {
	createBlockEvent?: FieldPolicy<any> | FieldReadFunction<any>,
	createBlockSchemaEvent?: FieldPolicy<any> | FieldReadFunction<any>,
	createDeviceEvent?: FieldPolicy<any> | FieldReadFunction<any>,
	createRequirementEvent?: FieldPolicy<any> | FieldReadFunction<any>,
	createRunEvent?: FieldPolicy<any> | FieldReadFunction<any>,
	logEvent?: FieldPolicy<any> | FieldReadFunction<any>
};
export type TargetKeySpecifier = ('name' | TargetKeySpecifier)[];
export type TargetFieldPolicy = {
	name?: FieldPolicy<any> | FieldReadFunction<any>
};
export type TelegramSettingsKeySpecifier = ('chatIds' | TelegramSettingsKeySpecifier)[];
export type TelegramSettingsFieldPolicy = {
	chatIds?: FieldPolicy<any> | FieldReadFunction<any>
};
export type UserKeySpecifier = ('admin' | 'changeDate' | 'creationDate' | 'email' | 'families' | 'groups' | 'id' | 'oauthUser' | 'projects' | 'stripeCustomerId' | 'subscription' | 'tier' | 'userType' | UserKeySpecifier)[];
export type UserFieldPolicy = {
	admin?: FieldPolicy<any> | FieldReadFunction<any>,
	changeDate?: FieldPolicy<any> | FieldReadFunction<any>,
	creationDate?: FieldPolicy<any> | FieldReadFunction<any>,
	email?: FieldPolicy<any> | FieldReadFunction<any>,
	families?: FieldPolicy<any> | FieldReadFunction<any>,
	groups?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	oauthUser?: FieldPolicy<any> | FieldReadFunction<any>,
	projects?: FieldPolicy<any> | FieldReadFunction<any>,
	stripeCustomerId?: FieldPolicy<any> | FieldReadFunction<any>,
	subscription?: FieldPolicy<any> | FieldReadFunction<any>,
	tier?: FieldPolicy<any> | FieldReadFunction<any>,
	userType?: FieldPolicy<any> | FieldReadFunction<any>
};
export type StrictTypedTypePolicies = {
	AppConfig?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AppConfigKeySpecifier | (() => undefined | AppConfigKeySpecifier),
		fields?: AppConfigFieldPolicy,
	},
	Artifact?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ArtifactKeySpecifier | (() => undefined | ArtifactKeySpecifier),
		fields?: ArtifactFieldPolicy,
	},
	Attachment?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AttachmentKeySpecifier | (() => undefined | AttachmentKeySpecifier),
		fields?: AttachmentFieldPolicy,
	},
	Binary?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | BinaryKeySpecifier | (() => undefined | BinaryKeySpecifier),
		fields?: BinaryFieldPolicy,
	},
	BinaryAnalysis?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | BinaryAnalysisKeySpecifier | (() => undefined | BinaryAnalysisKeySpecifier),
		fields?: BinaryAnalysisFieldPolicy,
	},
	BinaryPart?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | BinaryPartKeySpecifier | (() => undefined | BinaryPartKeySpecifier),
		fields?: BinaryPartFieldPolicy,
	},
	BinaryPartAnalysis?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | BinaryPartAnalysisKeySpecifier | (() => undefined | BinaryPartAnalysisKeySpecifier),
		fields?: BinaryPartAnalysisFieldPolicy,
	},
	Block?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | BlockKeySpecifier | (() => undefined | BlockKeySpecifier),
		fields?: BlockFieldPolicy,
	},
	BlockSchema?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | BlockSchemaKeySpecifier | (() => undefined | BlockSchemaKeySpecifier),
		fields?: BlockSchemaFieldPolicy,
	},
	Chip?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ChipKeySpecifier | (() => undefined | ChipKeySpecifier),
		fields?: ChipFieldPolicy,
	},
	DataBlock?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DataBlockKeySpecifier | (() => undefined | DataBlockKeySpecifier),
		fields?: DataBlockFieldPolicy,
	},
	Device?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DeviceKeySpecifier | (() => undefined | DeviceKeySpecifier),
		fields?: DeviceFieldPolicy,
	},
	DeviceConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DeviceConnectionKeySpecifier | (() => undefined | DeviceConnectionKeySpecifier),
		fields?: DeviceConnectionFieldPolicy,
	},
	DeviceEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DeviceEdgeKeySpecifier | (() => undefined | DeviceEdgeKeySpecifier),
		fields?: DeviceEdgeFieldPolicy,
	},
	Family?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | FamilyKeySpecifier | (() => undefined | FamilyKeySpecifier),
		fields?: FamilyFieldPolicy,
	},
	FlashChip?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | FlashChipKeySpecifier | (() => undefined | FlashChipKeySpecifier),
		fields?: FlashChipFieldPolicy,
	},
	Group?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | GroupKeySpecifier | (() => undefined | GroupKeySpecifier),
		fields?: GroupFieldPolicy,
	},
	IntegrationsSettings?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | IntegrationsSettingsKeySpecifier | (() => undefined | IntegrationsSettingsKeySpecifier),
		fields?: IntegrationsSettingsFieldPolicy,
	},
	Log?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | LogKeySpecifier | (() => undefined | LogKeySpecifier),
		fields?: LogFieldPolicy,
	},
	MatrixSettings?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MatrixSettingsKeySpecifier | (() => undefined | MatrixSettingsKeySpecifier),
		fields?: MatrixSettingsFieldPolicy,
	},
	MemoryEndpoint?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MemoryEndpointKeySpecifier | (() => undefined | MemoryEndpointKeySpecifier),
		fields?: MemoryEndpointFieldPolicy,
	},
	MutationRoot?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MutationRootKeySpecifier | (() => undefined | MutationRootKeySpecifier),
		fields?: MutationRootFieldPolicy,
	},
	Notification?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | NotificationKeySpecifier | (() => undefined | NotificationKeySpecifier),
		fields?: NotificationFieldPolicy,
	},
	NotificationConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | NotificationConnectionKeySpecifier | (() => undefined | NotificationConnectionKeySpecifier),
		fields?: NotificationConnectionFieldPolicy,
	},
	NotificationEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | NotificationEdgeKeySpecifier | (() => undefined | NotificationEdgeKeySpecifier),
		fields?: NotificationEdgeFieldPolicy,
	},
	OauthData?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | OauthDataKeySpecifier | (() => undefined | OauthDataKeySpecifier),
		fields?: OauthDataFieldPolicy,
	},
	OauthUser?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | OauthUserKeySpecifier | (() => undefined | OauthUserKeySpecifier),
		fields?: OauthUserFieldPolicy,
	},
	PageInfo?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageInfoKeySpecifier | (() => undefined | PageInfoKeySpecifier),
		fields?: PageInfoFieldPolicy,
	},
	PaymentMethod?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PaymentMethodKeySpecifier | (() => undefined | PaymentMethodKeySpecifier),
		fields?: PaymentMethodFieldPolicy,
	},
	Playbook?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PlaybookKeySpecifier | (() => undefined | PlaybookKeySpecifier),
		fields?: PlaybookFieldPolicy,
	},
	Project?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProjectKeySpecifier | (() => undefined | ProjectKeySpecifier),
		fields?: ProjectFieldPolicy,
	},
	ProjectSettings?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProjectSettingsKeySpecifier | (() => undefined | ProjectSettingsKeySpecifier),
		fields?: ProjectSettingsFieldPolicy,
	},
	Provisioner?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProvisionerKeySpecifier | (() => undefined | ProvisionerKeySpecifier),
		fields?: ProvisionerFieldPolicy,
	},
	ProvisionerReleases?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProvisionerReleasesKeySpecifier | (() => undefined | ProvisionerReleasesKeySpecifier),
		fields?: ProvisionerReleasesFieldPolicy,
	},
	QueryRoot?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | QueryRootKeySpecifier | (() => undefined | QueryRootKeySpecifier),
		fields?: QueryRootFieldPolicy,
	},
	Release?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ReleaseKeySpecifier | (() => undefined | ReleaseKeySpecifier),
		fields?: ReleaseFieldPolicy,
	},
	Requirement?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | RequirementKeySpecifier | (() => undefined | RequirementKeySpecifier),
		fields?: RequirementFieldPolicy,
	},
	RequirementCheck?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | RequirementCheckKeySpecifier | (() => undefined | RequirementCheckKeySpecifier),
		fields?: RequirementCheckFieldPolicy,
	},
	RttAnalysis?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | RttAnalysisKeySpecifier | (() => undefined | RttAnalysisKeySpecifier),
		fields?: RttAnalysisFieldPolicy,
	},
	Run?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | RunKeySpecifier | (() => undefined | RunKeySpecifier),
		fields?: RunFieldPolicy,
	},
	RunCommand?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | RunCommandKeySpecifier | (() => undefined | RunCommandKeySpecifier),
		fields?: RunCommandFieldPolicy,
	},
	RunStep?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | RunStepKeySpecifier | (() => undefined | RunStepKeySpecifier),
		fields?: RunStepFieldPolicy,
	},
	RunStepData?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | RunStepDataKeySpecifier | (() => undefined | RunStepDataKeySpecifier),
		fields?: RunStepDataFieldPolicy,
	},
	Station?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | StationKeySpecifier | (() => undefined | StationKeySpecifier),
		fields?: StationFieldPolicy,
	},
	Step?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | StepKeySpecifier | (() => undefined | StepKeySpecifier),
		fields?: StepFieldPolicy,
	},
	StripeData?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | StripeDataKeySpecifier | (() => undefined | StripeDataKeySpecifier),
		fields?: StripeDataFieldPolicy,
	},
	Subscription?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SubscriptionKeySpecifier | (() => undefined | SubscriptionKeySpecifier),
		fields?: SubscriptionFieldPolicy,
	},
	SubscriptionRoot?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SubscriptionRootKeySpecifier | (() => undefined | SubscriptionRootKeySpecifier),
		fields?: SubscriptionRootFieldPolicy,
	},
	Target?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | TargetKeySpecifier | (() => undefined | TargetKeySpecifier),
		fields?: TargetFieldPolicy,
	},
	TelegramSettings?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | TelegramSettingsKeySpecifier | (() => undefined | TelegramSettingsKeySpecifier),
		fields?: TelegramSettingsFieldPolicy,
	},
	User?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | UserKeySpecifier | (() => undefined | UserKeySpecifier),
		fields?: UserFieldPolicy,
	}
};
export type TypedTypePolicies = StrictTypedTypePolicies & TypePolicies;